import React, { useEffect, useState } from "react";
import { Checkbox, Grid, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";

const LossflowTable = ({ showCheckBox, setShowCheckBox }) => {
  const {  projectDetail } = useSelector((state) => state.user);
  const [loss,setLoss]=useState({
    module:null,
    soiling:null,
    shadow:null,
    insulationRegistance:null,
    overHeatingDCString:null
  })
  useEffect(()=>{
    setLoss({
      ...loss,
      module:projectDetail?.plantDetail?.plantDetail?.plantLoss?.module,
      soiling:projectDetail?.plantDetail?.plantDetail?.plantLoss?.soiling,
      shadow:projectDetail?.plantDetail?.plantDetail?.plantLoss?.shadow,
      insulationRegistance:projectDetail?.plantDetail?.plantDetail?.plantLoss?.insulationRegistance,
      overHeatingDCString:projectDetail?.plantDetail?.plantDetail?.plantLoss?.overHeatingDCString

    })


  },projectDetail)
  return (
    <div >
      <span style={{ fontSize: "3vmin", fontWeight: "600" }}>
        Loss Flow Table
      </span>
      <table
        className="loss_flow_table"
        style={{
          height: "50vh",
        }}
      >
        <tr style={{ backgroundColor: "#edeafb", padding: "0.5vmax 0" }}>
          <th></th>
          <th>Losses</th>
          <th>Description</th>
          <th>Loss (%)</th>
        </tr>
        <tr style={{ padding: "1vmax 0" }}>
          <td>
            <Checkbox
              id="module"
              checked={showCheckBox.Module}
              color="success"
              onChange={() =>
                setShowCheckBox({
                  ...showCheckBox,
                  Module: !showCheckBox.Module,
                })
              }
            />
          </td>

          <td style={{ textAlign: "center" }}>
            <InputLabel
              style={{ color: "black", fontSize: "2vmin" }}
              htmlFor="module"
            >
              Module
            </InputLabel>{" "}
          </td>

          <td style={{ textAlign: "center" }}>
            There is a multiple of module defects have been identified with
            bypass diode dominating with 5.25% impact on underperformance
          </td>
          <td style={{ textAlign: "center" }}>{loss.module || "N/A"} </td>
        </tr>

        <tr>
          <td>
            <Checkbox
              checked={showCheckBox.Soiling}
              id="soiling"
              color="success"
              onChange={() =>
                setShowCheckBox({
                  ...showCheckBox,
                  Soiling: !showCheckBox.Soiling,
                })
              }
            />
          </td>

          <td style={{ textAlign: "center" }}>
            {" "}
            <InputLabel
              style={{ color: "black", fontSize: "2vmin" }}
              htmlFor="soiling"
            >
              Soiling
            </InputLabel>{" "}
          </td>

          <td style={{ textAlign: "center" }}>
            We have observed a 3.5% gap between the actual soiling loss and
            PVSYST model
          </td>
          <td style={{ textAlign: "center" }}>{loss.soiling || "N/A"}</td>
        </tr>
        <tr>
          <td>
            <Checkbox
              id="shadow"
              checked={showCheckBox.Shadow}
              color="success"
              onChange={() =>
                setShowCheckBox({
                  ...showCheckBox,
                  Shadow: !showCheckBox.Shadow,
                })
              }
            />
          </td>

          <td style={{ textAlign: "center" }}>
            <InputLabel
              htmlFor="shadow"
              style={{ color: "black", fontSize: "2vmin" }}
            >
              {" "}
              Shadow Loss
            </InputLabel>{" "}
          </td>

          <td style={{ textAlign: "center" }}>
            There is 1.3% gap in actual plant performance compared to PVSYST
            model
          </td>
          <td style={{ textAlign: "center" }}>{loss.shadow || "N/A"}</td>
        </tr>
        <tr>
          <td>
            <Checkbox
              id="insulator"
              checked={showCheckBox.Resistance}
              color="success"
              onChange={() =>
                setShowCheckBox({
                  ...showCheckBox,
                  Resistance: !showCheckBox.Resistance,
                })
              }
            />
          </td>

          <td style={{ textAlign: "center" }}>
            <InputLabel
              htmlFor="insulator"
              style={{
                whiteSpace: "pre-wrap",
                color: "black",
                fontSize: "2vmin",
              }}
            >
              Insulation resistance of cable & connector
            </InputLabel>
          </td>

          <td style={{ textAlign: "center" }}>
            There are number of damage Y connector and DC string cable exposed
            to the sharp edge of thr module mountaining structure that require
            attention to avoid inverter tripping due to low insultion resistance
          </td>
          <td style={{ textAlign: "center" }}>{loss.insulationRegistance || "N/A"}</td>
        </tr>
        <tr>
          <td>
            <Checkbox
              id="overheating"
              checked={showCheckBox.Overheating}
              color="success"
              onChange={() =>
                setShowCheckBox({
                  ...showCheckBox,
                  Overheating: !showCheckBox.Overheating,
                })
              }
            />
          </td>

          <td style={{ textAlign: "center" }}>
            <InputLabel
              htmlFor="overheating"
              style={{
                whiteSpace: "pre-wrap",
                color: "black",
                fontSize: "2vmin",
              }}
            >
              Overheating DC string and DC main cables
            </InputLabel>
          </td>

          <td style={{ textAlign: "center" }}>
            IR inspection of the SMBs have indentified several overheating
            strings and Dc cable joint, which is causing derating of cables and
            could result into fire in high irradiance days
          </td>
          <td style={{ textAlign: "center" }}>{loss.overHeatingDCString+"%" || "N/A"}</td>
        </tr>
      </table>
    </div>
  );
};

export default LossflowTable;
