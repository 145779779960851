import React, { memo, useEffect, useMemo, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import "./daterange.css";
import Dropdown from "./Dropdown";
import {
  convertMonthToNumber,
  getDaysInMonth,
  getMonthValue,
} from "../constants/Utils";
import { useSelector } from "react-redux";

// const data = {
//   year: ["2024"],
//   month: [
//     {
//       year: "2024",
//       month: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
//     },
//   ],
// };
const DateRangeComp = ({
  selectedLebel,
  sestSelectedLebel,
  scbsmbDetail,
  
  labelData,
  selectedValue
}) => {
  const { projectDetail } = useSelector((state) => state.user);
  const [data, setData] = useState(null);


 

  const onchangeYear = (val, label) => {
   
    if (label == "Select Label") {
      if (selectedLebel.labelValue === val) return;

      val = val?.toLowerCase();

      if (val === "year") {
        sestSelectedLebel({
          ...selectedLebel,
          labelValue:val,
          [`${val}`]: !selectedLebel[`${val}`],

          day: false,
          month: false,
          yearData: null,
          monthData: null,
          endData: null,
          endDataValue: null,
          startDataValue: null,
          yearDataValue: !selectedLebel[`${val}`]
            ? null
            : selectedLebel.yearDataValue,
          monthDataValue: !selectedLebel[`${val}`]
            ? null
            : selectedLebel.yearDataValue,
          startData: !selectedLebel[`${val}`]
            ? data?.year?.map((e) => e)
            : null,
        });
      } else if (val === "month") {
        sestSelectedLebel({
          ...selectedLebel,
          [`${val}`]: !selectedLebel[`${val}`],
          labelValue:val,

          year: false,
          day: false,
          yearData: !selectedLebel[`${val}`] ? data?.year?.map((e) => e) : [],
          yearDataValue: !selectedLebel[`${val}`]
            ? null
            : selectedLebel.yearDataValue,
          monthDataValue: !selectedLebel[`${val}`]
            ? null
            : selectedLebel.monthDataValue,
          monthData: null,
          endData: null,
          startData: null,
          endDataValue: null,
          startDataValue: null,
        });
      } else {
        sestSelectedLebel({
          ...selectedLebel,
          year: false,
          month: false,
          [`${val}`]: !selectedLebel[`${val}`],
          labelValue:val,
          yearData: !selectedLebel[`${val}`] ? data?.year?.map((e) => e) : [],
          monthData: null,
          endData: null,
          startData: null,
          endDataValue: null,
          startDataValue: null,
          yearDataValue: !selectedLebel[`${val}`]
            ? null
            : selectedLebel.yearDataValue,
          monthDataValue: !selectedLebel[`${val}`]
            ? null
            : selectedLebel.monthDataValue,
        });
      }
    } else if (selectedLebel.year) {
      if (label == "Start Year") {
        sestSelectedLebel({
          ...selectedLebel,
          startDataValue: val,
          endDataValue: null,
          endData:
            data?.year?.slice(
              data?.year?.findIndex((e) => e == val),
              data?.year?.length
            ) || null,
        });
      } else {
        sestSelectedLebel({
          ...selectedLebel,
          endDataValue: val,
        });
      }
    } else if (selectedLebel.month) {
      if (label == "Select Year") {
        sestSelectedLebel({
          ...selectedLebel,
          yearDataValue: val,
          monthDataValue: null,
          startDataValue: null,
          endDataValue: null,
          startData: data?.month?.find((e) => e.year == val).month?.map(e=>e+"-"+val) || null,
          endData: null,
        });
      } else if (label == "Start Month") {
        sestSelectedLebel({
          ...selectedLebel,
          startDataValue: val?.split("-")[0],
          endDataValue: null,
          endData:
            selectedLebel.startData?.slice(
              selectedLebel.startData?.findIndex((e) => e == val),
              selectedLebel.startData?.length || 0
            ) || null,
        });
      } else if (label === "End Month") {
        sestSelectedLebel({
          ...selectedLebel,
          endDataValue: val?.split("-")[0],
        });
      }
    } else if (selectedLebel.day) {
      if (label === "Select Year") {
        sestSelectedLebel({
          ...selectedLebel,
          yearDataValue: val,
          monthData: data?.month?.find((e) => e.year == val).month?.map(e=>e+"-"+val) || null,
          monthDataValue: null,
          startDataValue: null,
          endDataValue: null,
          startData: null,
          endData: null,
        });
      } else if (label === "Select Month") {
        sestSelectedLebel({
          ...selectedLebel,
          monthDataValue: val?.split("-")[0],
          startDataValue: null,
          endDataValue: null,
          startData:
            // selectedLebel.yearDataValue?.split("-")?.length > 1
            //   ? getDaysInMonth(
            //       selectedLebel.yearDataValue?.split("-")[0],
            //       convertMonthToNumber(val)
            //     )
            //   :
               getDaysInMonth(
                  selectedLebel.yearDataValue,
                  convertMonthToNumber(val?.split("-")[0])
                ),
          endData: null,
        });
      } else if (label === "Start Day") {
        sestSelectedLebel({
          ...selectedLebel,
          startDataValue: val,
          endDataValue: null,
          endData:
            selectedLebel.startData?.slice(
              selectedLebel.startData?.findIndex((e) => e == val),
              selectedLebel.startData?.length || 0
            ) || null,
        });
      } else if (label === "End Day") {
        sestSelectedLebel({
          ...selectedLebel,
          endDataValue: val,
        });
      }
    }
  };
  useEffect(() => {

    if (projectDetail && selectedValue?.projectDetail) {

      setData(projectDetail?.plantDetail?.dataYear);
    }
  }, [selectedValue,  projectDetail]);




  return (
    <>
      <div className="date-range-container">
        <div>
          <Dropdown
            onchangeYear={onchangeYear}
            listData={labelData}
            label={"Select Label"}
            value={selectedLebel?.labelValue}
          />
          {/* <button
            onClick={() =>
              sestSelectedLebel({
                ...selectedLebel,
                year: !selectedLebel.year,
                day: false,
                month: false,
                yearData: null,
                monthData: null,
                endData: null,
                endDataValue: null,
                startDataValue: null,
                yearDataValue: !selectedLebel.year
                  ? null
                  : selectedLebel.yearDataValue,
                monthDataValue: !selectedLebel.year
                  ? null
                  : selectedLebel.yearDataValue,
                startData: !selectedLebel.year ? data?.year?.map((e) => e) : [],
              })
            }
            className={`${selectedLebel.year ? "selected-label" : ""}`}
          >
            Year
            <ArrowDropDownIcon />
          </button>
          <button
            onClick={() =>
              sestSelectedLebel({
                ...selectedLebel,
                month: !selectedLebel.month,
                year: false,
                day: false,
                yearData: !selectedLebel.month ? data?.year?.map((e) => e) : [],
                yearDataValue: !selectedLebel.month
                  ? null
                  : selectedLebel.yearDataValue,
                monthDataValue: !selectedLebel.month
                  ? null
                  : selectedLebel.monthDataValue,
                monthData: null,
                endData: null,
                startData: null,
                endDataValue: null,
                startDataValue: null,
              })
            }
            className={`${selectedLebel.month ? "selected-label" : ""}`}
          >
            Month
          </button>
          <button
            onClick={() =>
              sestSelectedLebel({
                ...selectedLebel,
                day: !selectedLebel.day,
                year: false,
                month: false,
                yearData: !selectedLebel.day ? data?.year?.map((e) => e) : [],
                monthData: null,
                endData: null,
                startData: null,
                endDataValue: null,
                startDataValue: null,
                yearDataValue: !selectedLebel.day
                  ? null
                  : selectedLebel.yearDataValue,
                monthDataValue: !selectedLebel.day
                  ? null
                  : selectedLebel.monthDataValue,
              })
            }
            className={`${selectedLebel.day ? "selected-label" : ""}`}
          >
            Day
          </button> */}
        </div>
        <div>
          {selectedLebel?.month ? (
            <Dropdown
              onchangeYear={onchangeYear}
              listData={selectedLebel?.yearData}
              label={"Select Year"}
              value={selectedLebel?.yearDataValue}
            />
          ) : selectedLebel?.day ? (
            <>
              <Dropdown
                onchangeYear={onchangeYear}
                listData={selectedLebel?.yearData}
                label={"Select Year"}
                value={selectedLebel?.yearDataValue}
              />
              <Dropdown
                onchangeYear={onchangeYear}
                listData={selectedLebel?.monthData}
                label={"Select Month"}
                value={selectedLebel?.monthDataValue}
              />
            </>
          ) : null}
        </div>
        <div>
          <Dropdown
            onchangeYear={onchangeYear}
            listData={selectedLebel?.startData}
            label={`Start ${
              selectedLebel?.year
                ? "Year"
                : selectedLebel?.month
                ? "Month"
                : selectedLebel?.day
                ? "Day"
                : ""
            }`}
            value={selectedLebel?.startDataValue}
          />

          <Dropdown
            onchangeYear={onchangeYear}
            listData={selectedLebel?.endData}
            label={`End ${
              selectedLebel?.year
                ? "Year"
                : selectedLebel?.month
                ? "Month"
                : selectedLebel?.day
                ? "Day"
                : ""
            }`}
            value={selectedLebel?.endDataValue}
          />

          {/* ###  Monthly Value component for Gaugemeter ### */}

          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <div
                className=""
                style={{
                  "& .MuiTextField-root": {
                    width: 200,
                  },
                }}
              >
                <DesktopDatePicker
                  disabled={dateRange.startDate ? false : true}
                  onChange={(value) =>
                    setDateRange({
                      ...dateRange,
                      startDateValue: value,
                    })
                  }
                  format="DD/MM/YYYY"
                  value={
                    dateRange.startDate ? dayjs(dateRange.startDate) : null
                  }
                  minDate={dayjs(dateRange.startDate).endOf("day")}
                  maxDate={dayjs(dateRange.endDate).endOf("month")}
                  label="Start Date"
                  views={["year", "month", "day"]}
                  slotProps={{ textField: { size: "small" } }}
                  sx={{
                    backgroundColor: "white",
                    border: "none",
                    borderRadius: "1px",
                    maxWidth: "125px",
                    width: "100%",
                    "& .MuiIconButton-root": {
                      backgroundColor: "white",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "2vmin",
                      color: "rgb(29, 26, 26)",
                      fontWeight: "600",
                    },

                    input: {
                      backgroundColor: "white",
                      fontSize: "2vmin",
                      // marginTop: "0.1rem",
                      color: "rgb(29, 26, 26)",
                    },
                    svg: {
                      marginTop: "0px",
                      fontSize: "2.5vmin",
                      fontWeight: 900,
                      fill: "#203864",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "2px solid #203864",
                        // borderColor: "red",
                      },
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "purple",
                      },
                    },
                  }}
                />
              </div>
              <div
                style={{
                  "& .MuiTextField-root": {
                    width: 200,
                  },
                }}
              >
                <DesktopDatePicker
                  disabled={dateRange.startDate ? false : true}
                  onChange={(value) => {
                    setDateRange({
                      ...dateRange,
                      endDateValue: value,
                    });
                  }}
                  format="DD/MM/YYYY"
                  value={
                    dateRange.endDate
                      ? dayjs(dateRange.endDate).endOf("month")
                      : null
                  }
                  views={["year", "month", "day"]}
                  minDate={dayjs(dateRange.startDate).endOf("day")}
                  maxDate={dayjs(dateRange.endDate).endOf("month")}
                  label="End Date"
                  slotProps={{ textField: { size: "small" } }}
                  sx={{
                    backgroundColor: "white",
                    border: "none",
                    borderRadius: "1px",
                    maxWidth: "125px",
                    width: "100%",
                    "& .MuiIconButton-root": {
                      backgroundColor: "white",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "2vmin",
                      color: "rgb(29, 26, 26)",
                      fontWeight: "600",
                    },

                    input: {
                      backgroundColor: "white",
                      fontSize: "2vmin",
                      // marginTop: "0.1rem",
                      color: "rgb(29, 26, 26)",
                    },
                    svg: {
                      marginTop: "0px",
                      fontSize: "2.5vmin",
                      fontWeight: 900,
                      fill: "#203864",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "2px solid #203864",
                        // borderColor: "red",
                      },
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "purple",
                      },
                    },
                  }}
                />
              </div>
            </DemoContainer>
          </LocalizationProvider> */}
        </div>
      </div>
    </>
  );
};

export default memo(DateRangeComp);
