import { colors3 } from "../colors/color";

/* #####  Function for generate random number between 0 to 9  ##### */

export const getRandomNumber = () => {
  try {
    return Math.round(Math.random() * 9);
  } catch (error) {
    console.log(error);
  }
};

/* #####  Function for get random Letter Among "ABCDEFabcdef"  ##### */

export const getRandomLetter = () => {
  try {
    const charList = "ABCDEFabcdef";
    const index = Math.round(Math.random() * (charList?.length - 1));
    return charList[index];
  } catch (error) {
    console.log(error);
  }
};

/* #####  Function for get Hexa color code  ##### */

export const getColourPicker = (index) => {
  const hexColourLength = 6;
  let colourCode = "#";
  try {
    if (index < colors3.length) return colors3[index];
    else {
      for (let i = 0; i < hexColourLength; i++) {
        const isLetter = Math.round(Math.random());
        if (isLetter) {
          colourCode = colourCode + getRandomLetter();
        } else {
          colourCode = colourCode + getRandomNumber();
        }
      }
      return colourCode;
    }
  } catch (error) {
    console.log(error);
  }
};

/* #####  Function to generate data url to download it in jpg format  ##### */

export const saveToSvg = (svg, width, height) => {
  return new Promise((resolve, reject) => {
    let canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    let ctx = canvas.getContext("2d");
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, width, height);

    let xml = new XMLSerializer().serializeToString(svg);
    let dataUrl = "data:image/svg+xml;utf8," + encodeURIComponent(xml);
    resolve(dataUrl);
  });
};

/* #####  Function to convert number to ReadableValue using separater like -->,  ##### */

export const convertToReadableValue = (val) => {
  if (!val) return 0;
  let string = "" + val;
  let arr = string.split("");
  arr.splice(1, 0, ",");
  arr.splice(4, 0, ",");
  arr.splice(7, 0, ",");
  return arr.join("");
};

/* #####  To set Default axis label value in graphs  ##### */

export const AxisLabel = ({
  axisType,
  x = 10,
  y = 90,
  width = 50,
  height = 50,
  stroke,
  children,
}) => {
  const isVert = axisType === "yAxis";
  const cx = isVert ? x : x + width / 2;
  const cy = isVert ? height / 2 + y : y + height + 10;
  const rot = isVert ? `270 ${cx} ${cy}` : 0;
  return (
    <text
      className="animation_label"
      style={{ boxSizing: "border-box" }}
      enableBackground={true}
      x={`${cx}%`}
      y={`${cy}%`}
      transform={`rotate(${rot})`}
      textAnchor="middle"
      stroke={stroke}
    >
      {children}
    </text>
  );
};

export const getMonthValue = (val) => {
  if(val==1 || val=="01")return "January"
  else if(val==2 || val=="02")return "February"
  else if(val==3 || val=="03")return "March"
  else if(val==4 || val=="04")return "April"
  else if(val==5 || val=="05")return "May"
  else if(val==6 || val=="06")return "June"
  else if(val==7 || val=="07")return "July"
  else if(val==8 || val=="08")return "August"
  else if(val==9 || val=="09")return "September"
  else if(val==10 || val=="10")return "October"
  else if(val==11 || val=="11")return "November"
  else return "December"
};

export const convertMonthToNumber = (val) => {
  if (val == "Jan") return 0;
  else if (val == "Feb") return 1;
  else if (val == "Mar") return 2;
  else if (val == "Apr") return 3;
  else if (val == "May") return 4;
  else if (val == "Jun") return 5;
  else if (val == "Jul") return 6;
  else if (val == "Aug") return 7;
  else if (val == "Sep") return 8;
  else if (val == "Oct") return 9;
  else if (val == "Nov") return 10;
  else if (val == "Dec") return 11;
  else return 11;
};

export const getDaysInMonth = (year, month) => {
  var end = new Date(year, month + 1, 0).getDate();

  return Array.from({ length: end }, (_, i) => 1 + i);
};

export const startYearToTimeStamps = (year) => {
  if (!year) return null;

  let start = new Date(`${year}-01-01T00:00:00`).getTime() / 1000;

  return start;
};

export const endYearToTimeStamps = (year) => {
  if (!year) return null;

  let end = new Date(`${year}-12-31T23:59:59`).getTime() / 1000;

  return end;
};

export const startMonthToTimeStamps = (year, month) => {
  month += 1;
  month = month.toString().padStart(2, "0");

  if (!year || month == undefined || month == null || month == "") return 0;
  month.toString().padStart(2, "0");

  let start = new Date(`${year}-${month}-01T00:00:00`).getTime() / 1000;
  return start;
};

export const endMonthToTimeStamps = (year, month) => {
  month += 1;
  month = month.toString().padStart(2, "0");

  if (!year || month == undefined || month == null || month == "") return 0;

  let nextMonth = new Date(`${year}-${month}-01T00:00:00`);
  nextMonth.setMonth(nextMonth.getMonth() + 1); // Move to the next month
  let end = (nextMonth.getTime() - 1) / 1000; //
  return end;
};

export const startDayToTimeStamps = (year, month, day) => {
  month += 1;
  month = month.toString().padStart(2, "0");

  
  day = day.toString().padStart(2, "0");

  if (!year || month == undefined || month == null || month == "" || !day)
    return 0;

  let start = new Date(`${year}-${month}-${day}T00:00:00`).getTime() / 1000;

  return start;
};

export const endDayToTimeStamps = (year, month, day) => {
  month += 1;
  month = month.toString().padStart(2, "0");
  day = day.toString().padStart(2, "0");
  if (!year || month == undefined || month == null || month == "" || !day)
    return 0;
  let end = new Date(`${year}-${month}-${day}T23:59:59`).getTime() / 1000;
  return end;
};



export const calculateRange=(num1, num2) =>{
  const minNumber = Math.min(num1, num2);
  const maxNumber = Math.max(num1, num2);
  
  // Adjust base values as needed for min and max
  const minBase = 10;
  const maxBase = Math.pow(10, Math.ceil(Math.log10(maxNumber))); // Dynamic base
  
  const minValue = Math.floor(minNumber / minBase) * minBase;
  const maxValue = Math.ceil(maxNumber / maxBase) * maxBase;
  
  return { minValue, maxValue };
}

// console.log(calculateRange(289, 269));   // { minValue: 200, maxValue: 300 }
// console.log(calculateRange(4588, 239));  // { minValue: 200, maxValue: 5000 }
