import React, { useState } from 'react';
import { ListSubheader, MenuItem, Box } from '@mui/material'; // Assuming you are using MUI

let data = {
    _id: "66e6b1bd4db915cbf85c5169",
    clientEmailID: "ipcl@gmail.com",
    projectName: "IndiaIPCLPLSPL",
    stringDetail: {
      stringType: "SCB",
      stringArray: [
        {
          name: "Block1",
          inverters: [
            { name: "Inverter1", listSCBs: ["SCB1", "SCB2", "SCB3"] },
            { name: "inverter2", listSCBs: ["SCB4", "SCB5", "SCB6"] },
            { name: "Inverter3", listSCBs: ["SCB1", "SCB2", "SCB3"] },
            { name: "inverter4", listSCBs: ["SCB4", "SCB5", "SCB6"] },
          ],
        },
        {
          name: "Block2",
          inverters: [
            { name: "inverter1", listSCBs: ["SCB7", "SCB8"] },
            { name: "inverter2", listSCBs: ["SCB9", "SCB10"] },
          ],
        },
      ],
    },
  };
const YourComponent2 = () => {
  // State to track which subheader and child items are expanded
  const [expanded, setExpanded] = useState({});

  // Handle the click on the ListSubheader to toggle visibility
  const handleToggle = (key) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [key]: !prevExpanded[key], // Toggle the state for the specific key (preserving others)
    }));
  };

  return (
    <>
     {
               data.stringDetail.stringArray.map((e) => {
                  return (
                    <Box key={e.name}>
                      {/* First Subheader */}
                      <ListSubheader key={e.name} className="SubHeader">
                        <span
                          onClick={() => handleToggle(e.name, "string_smb")}
                        >
                          {e.name}
                          <i
                            className={`fa-solid fa-angle-down ${
                              expanded[e.name] ? "active" : ""
                            }`}
                          ></i>
                        </span>
                      </ListSubheader>

                      {/* Conditionally show inverters if checked */}
                      {expanded[e.name] &&
                        e?.inverters?.map((ee) => {
                          return (
                            <Box key={ee.name}>
                              <ListSubheader
                                key={ee.name}
                                className="SubHeader"
                              >
                                <span
                                  onClick={() =>
                                    handleToggle(
                                      e.name + ee.name,
                                      "string_smb",
                                      e.name
                                    )
                                  }
                                >
                                  {ee.name}
                                  <i
                                    className={`fa-solid fa-angle-down ${
                                      expanded[e.name + ee.name]
                                        ? "active"
                                        : ""
                                    }`}
                                  ></i>
                                </span>
                              </ListSubheader>

                              {/* Conditionally show SCBs if checked */}
                              {expanded[e.name + ee.name] &&
                                ee?.listSCBs?.map((eee, index) => {
                                  return (
                                    <MenuItem
                                      key={e.name + ee.name}
                                      value={eee}
                                      onClick={() => {
                                        // You can navigate to e.url here
                                      }}
                                    >
                                      {eee}
                                    </MenuItem>
                                  );
                                })}
                            </Box>
                          );
                        })}
                    </Box>
                  );
                })}  
    </>
  );
};

export default YourComponent2;
