import React, { useRef, useState } from "react";
import Table from "../../../../components/Table";

const data = [
  {
    name: "Module",
    Loss: 5.25,
    // fill: "#FF0000"
    fill: "#de425b",
  },
  {
    name: "Soiling",
    Loss: 3.5,
    // fill:"rgb(227, 94, 27)"
    fill: "#e18745",
  },
  {
    name: "Shadow",
    Loss: 1.3,
    // fill: "#FFA500"
    fill: "#dac767",
  },
  {
    name: "Resistance",
    Loss: 1,
    // fill:"rgb(223, 200, 51)"
    fill: "#78ab63",
  },
  {
    name: "Overheating",
    Loss: 1,
    // fill: "rgb(27, 84, 227)"
    // fill: "rgb(24, 134, 245)"
    // fill:"rgb(252, 237, 107)"
    // fill:"rgb(94, 216, 253)"
    fill: "#488f31",
  },
];
const PrescriptiveTable = () => {
  const [dataSet, setDataSet] = useState(data);
  const [showCheckBox, setShowCheckBox] = useState({
    Module: true,
    Soiling: true,
    Shadow: true,
    Resistance: true,
    Overheating: true,
  });

  const onChangeCheckBox = ({ name }) => {
    try {
      const newData1 = data?.filter((item) => {
        if (item?.name !== name) {
          if (showCheckBox[item?.name]) return item;
          return null;
        } else {
          if (!showCheckBox[item?.name]) return item;
          return null;
        }
      });
      const checked = { ...showCheckBox };
      checked[name] = !checked[name];
      setShowCheckBox(checked);
      setDataSet(newData1);
    } catch (error) {
      console.log(error);
    }
  };

  return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Table onChange={onChangeCheckBox} />
      </div>
  );
};

export default PrescriptiveTable;
