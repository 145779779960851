import React from 'react'
import "./Loader.css"

const DownloadLoader = () => {
  return (
    <div className='download-loader'>
        
    </div>
  )
}

export default DownloadLoader