import React, { useEffect } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import { convertToReadableValue } from "../constants/Utils";

const SpeedChart = (props) => {
  // Check if minValue and maxValue are valid numbers
  const minValue = typeof props.minValue === "number" && !isNaN(props.minValue) ? props.minValue : 0;
  const maxValue = typeof props.maxValue === "number" && !isNaN(props.maxValue) ? props.maxValue : 0;

  // Use key to force re-render when min or max changes
  const key = `${minValue}-${maxValue}`;

  useEffect(() => {
  }, [minValue, maxValue]);

  return (
    <div  className="speed-chart-wrapper">
      <ReactSpeedometer
        key={key} // Force ReactSpeedometer to re-render when minValue/maxValue change
        minValue={minValue}
        maxValue={maxValue}
        value={ Number(props?.value) || 0}
        // segments={props?.segments}
        segmentColors={[
          "#00D2FC",
          "#00E1ED",
          "#22EED1",
          "#78F6AD",
          "#B9FA8A",
          "#F9F871",
        ]}
        needleTransition="easeSinIn"
        ringWidth={70}
        fluidWidth={true}
      />
      <h3 style={{ textAlign: "center" }}>{props?.title}</h3>
    </div>
  );
};

export default SpeedChart;


const formatValue = (value) => {
  if (value >= 1000 && value < 1000000) {
    return (value / 1000).toFixed(1) + "k";
  } else if (value >= 1000000) {
    return (value / 1000000).toFixed(1) + "M";
  }
  return value;
};

