import React from 'react'
import "./Loader.css"

const GraphLoader = () => {
  return (
    <div className='graph-loader'>
        
    </div>
  )
}

export default GraphLoader