// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.main-body-1 {
  height: 100%;
  position: relative;

}

.country-body {
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.country-body>img {
  max-height:100%;
  min-width: 100%;
  transition: .3s ease-in-out;
  
}`, "",{"version":3,"sources":["webpack://./src/modules/countries/components/CountryDefault.css"],"names":[],"mappings":";AACA;EACE,YAAY;EACZ,kBAAkB;;AAEpB;;AAEA;EACE,WAAW;EACX,0BAA0B;EAC1B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;AAEzB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,2BAA2B;;AAE7B","sourcesContent":["\n.main-body-1 {\n  height: 100%;\n  position: relative;\n\n}\n\n.country-body {\n  width: 100%;\n  height: calc(100vh - 60px);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  \n}\n\n.country-body>img {\n  max-height:100%;\n  min-width: 100%;\n  transition: .3s ease-in-out;\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
