import './App.css';
import PageURL from '../src/constants/PageURL'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AuthModule from '../src/modules/auth/AuthModule'
import ProtectedRoute from "./modules/auth/ProtectedRoute";
import CountryModule from "./modules/countries/CountryModule";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getUser } from './actions/userActions';
import Footer from './components/Footer';
// import Analytics from './modules/countries/components/Analyctis/Analytics';
import CountryDefault from './modules/countries/components/CountryDefault';

function App() {

  const { user } = useSelector(state => state.user)
  const location = useLocation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUser())
  }, [dispatch])
  const path = location.pathname;
  


  return (
    <>
      <div className="App">
        <ToastContainer />
        {PageURL.BASE === location.pathname && user && <Navigate to="/dashboard" />}

        <Routes>
          <Route path={PageURL.BASE} element={<AuthModule />}></Route>
          <Route path={PageURL.SIGN_UP} element={<AuthModule />}></Route>
          <Route path={PageURL.FORGOT_PASSWORD} element={<AuthModule />}></Route>
          <Route path={PageURL.OTP_VERIFICATION} element={<AuthModule />}></Route>


          <Route element={<ProtectedRoute />}>
            <Route path={PageURL.COUNTRYDEFAULT} element={<CountryDefault />}> </Route>
            <Route path={PageURL.INDIA_GEN_SUMMARY} element={<CountryModule />} />
            <Route path={PageURL.INDIA_PRES_MODEL_Loss_Flow} element={<CountryModule />} />
            <Route path={PageURL.INDIA_PRES_MODEL_Fault_Tree} element={<CountryModule />} />
            <Route path={PageURL.PROJECT_DETAILS} element={<CountryModule />} />
            <Route
              path={PageURL.INDIA_PROJ_OVERVIEW}
              element={<CountryModule />}
            />
            <Route path={PageURL.INDIA_DIAGONISTIC} element={<CountryModule />}></Route>
            <Route
              path={PageURL.INDIA_DIAGONISTIC_DETAILED}
              element={<CountryModule />}
            />
            <Route path={PageURL.INDIA_LOSS_FLOW} element={<CountryModule />} />
            <Route
              path={PageURL.INDIA_INVERTER_EFFICIENCY}
              element={<CountryModule />}
            />

            <Route path={PageURL.SCBSMB_LOSS} element={<CountryModule/>}/>
            {
              (
                location.pathname === PageURL.INDIA_INVERTER1_SCB_SMB1 ||
                location.pathname === PageURL.INDIA_INVERTER1_SCB_SMB2 ||
                location.pathname === PageURL.INDIA_INVERTER1_SCB_SMB3 ||
                location.pathname === PageURL.INDIA_INVERTER1_SCB_SMB4 ||
                location.pathname === PageURL.INDIA_INVERTER1_SCB_SMB5 ||
                location.pathname === PageURL.INDIA_INVERTER1_SCB_SMB6 ||
                location.pathname === PageURL.INDIA_INVERTER1_SCB_SMB7 ||
                location.pathname === PageURL.INDIA_INVERTER1_SCB_SMB8 ||
                location.pathname === PageURL.INDIA_INVERTER1_SCB_SMB9 ||
                location.pathname === PageURL.INDIA_INVERTER1_SCB_SMB10 ||
                location.pathname === PageURL.INDIA_INVERTER1_SCB_SMB11 ||

                location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB1 ||
                location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB2 ||
                location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB3 ||
                location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB4 ||
                location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB5 ||
                location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB6 ||
                location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB7 ||
                location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB8 ||
                location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB9 ||
                location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB10 ||
                location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB11 ||
                location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB11 ||
                location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB12 ||

                location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB1 ||
                location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB2 ||
                location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB3 ||
                location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB4 ||
                location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB5 ||
                location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB6 ||
                location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB7 ||
                location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB8 ||
                location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB9 ||
                location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB10 ||
                location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB11 ||
                location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB11 ||
                location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB12 ||

                location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB1 ||
                location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB2 ||
                location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB3 ||
                location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB4 ||
                location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB5 ||
                location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB6 ||
                location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB7 ||
                location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB8 ||
                location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB9 ||
                location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB10 ||
                location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB11 ||
                location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB11 ||
                location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB12 ||
                location.pathname === PageURL.INDIA_INVERTER1_HEATMAP_DIAGRAM ||
                location.pathname === PageURL.INDIA_INVERTER2_HEATMAP_DIAGRAM ||
                location.pathname === PageURL.INDIA_INVERTER3_HEATMAP_DIAGRAM ||
                location.pathname === PageURL.INDIA_INVERTER4_HEATMAP_DIAGRAM
              )
              &&
              <Route
                path={path}
                element={<CountryModule />}
              />
            }
            <Route path={PageURL.PROJECT_ANALYTICS} element={<CountryDefault />} />
            <Route path="*" element={<Navigate to={PageURL.COUNTRYDEFAULT} />} />
          </Route>
          <Route path="*" element={<Navigate to={PageURL.BASE} />} />
        </Routes>
      </div>
      

    </>

  );
}

export default App;



// {!activeLink2.total &&
//   <span className='date-container'
//   >

//       <i
//           style={{ position: "absolute", left: "10%", zIndex: 1, fontSize: "0.8vmax" }}
//           className="fa-solid fa-angle-left"
//           onClick={() => handleLeftClickDate()}

//       ></i>
//       <i
//           style={{
//               position: "absolute",
//               right: "10%",
//               zIndex: 1,
//               fontSize: "0.8vmax",
//               cursor: showCursorOnDate.right ? "pointer" : "not-allowed"
//           }}

//           className="fa-solid fa-angle-right"
//           onClick={() => handleRightClickDate()}

//       ></i>
//       {/* <Datetime className='date'
//           timeFormat={false}
//           // initialValue={DateStr}
//           // value={DateStr}
//           initialValue={DateStr}
//           renderDay={renderDay}
//           renderMonth={renderMonth}
//           renderYear={renderYear}
//           dateFormat={activeLink2.day ? "DD/MM/YYYY" : activeLink2.month ? "MM/YYYY" : activeLink2.year ? "YYYY" : "DD/MM/YYYY"}
//           onChange={(e) => convertDateToReadbleFormat(e._d)
//           }
//           closeOnClickOutside={true}
//           closeOnSelect={true}
//           inputProps={{
//               readOnly: true,
//           }}
//       /> */}

//       {
//           activeLink2.day &&
//           (
//               <Datetime className='date'
//                   timeFormat={false}
//                   // initialValue={DateStr}
//                   // value={DateStr}
//                   initialValue={`${DateStr.split("/")[0]}/${DateStr.split("/")[1]}/${DateStr.split("/")[2]}`}

//                   dateFormat={"DD/MM/YYYY"}
//                   onChange={(e) => convertDateToReadbleFormat(e._d)
//                   }
//                   closeOnClickOutside={true}
//                   closeOnSelect={true}
//                   inputProps={{
//                       readOnly: true,
//                   }}
//               />
//           )
//       }
//       {
//           activeLink2.month &&
//           (
//               <Datetime className='date'
//                   timeFormat={false}
//                   // initialValue={DateStr}
//                   // value={DateStr}
//                   initialValue={`${DateStr.split("/")[1]}/${DateStr.split("/")[2]}`}


//                   dateFormat={"MM/YYYY"}
//                   onChange={(e) => convertDateToReadbleFormat(e._d)
//                   }
//                   closeOnClickOutside={true}
//                   closeOnSelect={true}
//                   inputProps={{
//                       readOnly: true,
//                   }}
//               />
//           )
//       }
//       {
//           activeLink2.year &&
//           <Datetime className='date'
//               timeFormat={false}
//               // initialValue={DateStr}
//               // value={DateStr}
//               initialValue={`${DateStr}`.split("/")[2]}
//               dateFormat={"YYYY"}
//               onChange={(e) => convertDateToReadbleFormat(e._d)
//               }
//               closeOnClickOutside={true}
//               closeOnSelect={true}
//               inputProps={{
//                   readOnly: true,
//               }}
//           />
//       }
//   </span>
// }
