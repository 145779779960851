import React from "react";
import Button from "@mui/material/Button";
import "./ButtonsComp.css";

/* #####  ButtonCompo for Login,Signup,Forgotpassword and OtpVerify Component  ##### */ 

const ButtonsComp = (props) => {
  return (
    <Button
      variant={props?.type}
      style={{
        margin: "5px",
        height: "6vh",
        width: "8vmax",
        color: "black",
        fontWeight: "bolder",
        whiteSpace:"nowrap",
        // backgroundColor:"rgb(236, 85, 15)"
        backgroundColor: props?.backgroundColor ? props.backgroundColor : props?.buttonType === "primary" ? "#ed7d31" : "white"
      }}
      onClick={props?.onClick}
      className="button-btn"
    >
      {props?.buttonText}
      
    </Button>
  );
};

export default ButtonsComp;
