import axios from "axios";
import {
  acLineLoss_actualPr_fail,
  acLineLoss_actualPr_request,
  acLineLoss_actualPr_sucess,
  asbuilt_document_fail,
  asbuilt_document_request,
  asbuilt_document_sucess,
  download_asbuilt_document_fail,
  download_asbuilt_document_request,
  download_asbuilt_document_sucess,
  GHI_GTI_data_fail,
  GHI_GTI_data_request,
  GHI_GTI_data_sucess,
  inverter_efficiency_fail,
  inverter_efficiency_request,
  inverter_efficiency_sucess,
  net_contractual_shortfall_fail,
  net_contractual_shortfall_request,
  net_contractual_shortfall_sucess,
  net_normalised_shortfall_fail,
  net_normalised_shortfall_request,
  net_normalised_shortfall_sucess,
  project_detail_fail,
  project_detail_request,
  project_detail_sucess,
  pvsyst_actual_temp_fail,
  pvsyst_actual_temp_request,
  pvsyst_actual_temp_sucess,
  pvsyst_contractual_energy_fail,
  pvsyst_contractual_energy_request,
  pvsyst_contractual_energy_sucess,
  scbsmb_monthly_loss_fail,
  scbsmb_monthly_loss_request,
  scbsmb_monthly_loss_reset,
  scbsmb_monthly_loss_sucess,
} from "../constants/dataConstants";
import {
  convertMonthToNumber,
  endDayToTimeStamps,
  endMonthToTimeStamps,
  endYearToTimeStamps,
  getMonthValue,
  startDayToTimeStamps,
  startMonthToTimeStamps,
  startYearToTimeStamps,
} from "../constants/Utils";

axios.defaults.baseURL = "https://pvapm-backend.greenencopvapm.com";
axios.defaults.withCredentials = true;

export const scbsmbLossMonthlyAction =
  (detail, dateRange, title,databaseStr) => async (dispatch) => {
    try {
      let label;
      let startDate, endDate;
      if (dateRange["year"] && dateRange["endDataValue"]) {
        startDate = startYearToTimeStamps(dateRange["startDataValue"]);
        endDate = endYearToTimeStamps(dateRange["endDataValue"]);
        label = "Year";
      } else if (dateRange["month"] && dateRange["endDataValue"]) {
        startDate = startMonthToTimeStamps(
          dateRange["yearDataValue"],
          convertMonthToNumber(dateRange["startDataValue"])
        );
        endDate = endMonthToTimeStamps(
          dateRange["yearDataValue"],
          convertMonthToNumber(dateRange["endDataValue"])
        );
        label = "Month";
      } else if (dateRange["day"] && dateRange["endDataValue"]) {
        startDate = startDayToTimeStamps(
          dateRange["yearDataValue"],
          convertMonthToNumber(dateRange["monthDataValue"]),
          dateRange["startDataValue"]
        );
        endDate = endDayToTimeStamps(
          dateRange["yearDataValue"],
          convertMonthToNumber(dateRange["monthDataValue"]),
          dateRange["endDataValue"]
        );
        label = "Day";
      }

      let { inverter, block, inverterType, scbsmb } = detail;

      inverter = inverter?.split(" ")[1];
      block = block?.split(" ")[1];
      scbsmb = scbsmb?.split(" ")[1];
   

      if (
        !startDate &&
        !endDate &&
        title ===
          "BL-" + block + " " + "INV-" + inverter + " " + "SCB-" + scbsmb
      )
        return;
      dispatch({ type: scbsmb_monthly_loss_request });

      const { data } = await axios.post(`/scbsmbLoss`, {
        inverter,
        block,
        inverterType,
        scbsmb,
        startDate,
        endDate,
        label,
        databaseStr
      });

      dispatch({
        type: scbsmb_monthly_loss_sucess,
        payload: data?.result || null,
      });
    } catch (error) {
      dispatch({
        type: scbsmb_monthly_loss_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };

export const projectDetailAction =
  (userName, projectStr) => async (dispatch) => {
    try {
      dispatch({ type: project_detail_request });

      const { data } = await axios.post("/projectDetail", {
        userName,
        projectStrDetail: projectStr,
      });
      dispatch({
        type: project_detail_sucess,
        payload: data || null,
      });
    } catch (error) {
      dispatch({
        type: project_detail_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };

export const inverterEfficiencyAction =
  (detail, dateRange,databaseStr) => async (dispatch) => {
    try {
      const {label,startDate,endDate}=getStartEndDateAndLabel(dateRange)
      let { inverter, block, inverterType } = detail;

      inverter = inverter?.split(" ")[1];
      block = block?.split(" ")[1];

      dispatch({ type: inverter_efficiency_request });
     

      const { data } = await axios.post(`/inverterEfficiency`, {
        inverter,
        block,
        inverterType,
        startDate,
        endDate,
        label,
        databaseStr
      });

      dispatch({
        type: inverter_efficiency_sucess,
        payload: data?.result || null,
      });
    } catch (error) {
      dispatch({
        type: inverter_efficiency_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };



  export const GHIGTIAction =
  ( dateRange,databaseStr) => async (dispatch) => {
    try {
      const {startDate,endDate,label}=getStartEndDateAndLabel(dateRange)
      dispatch({ type: GHI_GTI_data_request });

      const { data } = await axios.post(`/GHIGTI`, {
        
        startDate,
        endDate,
        label,
        databaseStr
      });

      dispatch({
        type: GHI_GTI_data_sucess,
        payload: data?.result || null,
      });
    } catch (error) {
      dispatch({
        type: GHI_GTI_data_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };


  
  export const pvsystActualTempAction =
  ( dateRange,databaseStr) => async (dispatch) => {
    try {
      const {startDate,endDate,label}=getStartEndDateAndLabel(dateRange)

      dispatch({ type: pvsyst_actual_temp_request });

      const { data } = await axios.post(`/pvsystActualTemp`, {
        startDate,
        endDate,
        label,
        databaseStr
      });

      dispatch({
        type: pvsyst_actual_temp_sucess,
        payload: data?.result || null,
      });
    } catch (error) {
      dispatch({
        type: pvsyst_actual_temp_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };



  export const pvsystContractualEnergyAction =
  ( dateRange,databaseStr) => async (dispatch) => {
    try {
      const {startDate,endDate,label}=getStartEndDateAndLabel(dateRange)
      
      dispatch({ type: pvsyst_contractual_energy_request });

      const { data } = await axios.post(`/pvsystContractualEnergy`, {
        startDate,
        endDate,
        label,
        databaseStr
      });

      dispatch({
        type: pvsyst_contractual_energy_sucess,
        payload: data?.result || null,
      });
    } catch (error) {
      dispatch({
        type: pvsyst_contractual_energy_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };



  export const netNormalisedEnergyShortfallAction =
  ( dateRange,databaseStr) => async (dispatch) => {
    try {
      const {startDate,endDate,label}=getStartEndDateAndLabel(dateRange)
      
      dispatch({ type: net_normalised_shortfall_request });

      const { data } = await axios.post(`/netNormalisedEnergyShortfall`, {
        startDate,
        endDate,
        label,
        databaseStr
      });

      dispatch({
        type: net_normalised_shortfall_sucess,
        payload: data?.result || null,
      });
    } catch (error) {
      dispatch({
        type: net_normalised_shortfall_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };


  export const netContractualEnergyShortfallAction =
  ( dateRange,databaseStr) => async (dispatch) => {
    try {
      const {startDate,endDate,label}=getStartEndDateAndLabel(dateRange)
      
      dispatch({ type: net_contractual_shortfall_request });

      const { data } = await axios.post(`/netContractualEnergyShortfall`, {
        startDate,
        endDate,
        label,
        databaseStr
      });

      dispatch({
        type: net_contractual_shortfall_sucess,
        payload: data?.result || null,
      });
    } catch (error) {
      dispatch({
        type: net_contractual_shortfall_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };


  export const acLineLossActualPrAction =
  ( dateRange,databaseStr) => async (dispatch) => {
    try {
      const {startDate,endDate,label}=getStartEndDateAndLabel(dateRange)
      
      dispatch({ type: acLineLoss_actualPr_request });

      const { data } = await axios.post(`/acLineLossActualPr`, {
        startDate,
        endDate,
        label,
        databaseStr
      });

      dispatch({
        type: acLineLoss_actualPr_sucess,
        payload: data?.result || null,
      });
    } catch (error) {
      dispatch({
        type: acLineLoss_actualPr_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };


  export const getAsbuiltDocuments =
  ( category,name,databaseStr) => async (dispatch) => {
    try {
     
      
      dispatch({ type: asbuilt_document_request });

      const { data } = await axios.post(`/asbuiltDocuments`, {
        category,name,
       projectName: databaseStr
      });
      dispatch({
        type: asbuilt_document_sucess,
        payload: data?.result || null,
      });
    } catch (error) {
      dispatch({
        type: asbuilt_document_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };

  export const downloadAsbuiltDocuments =
  ({name,category,projectName, fileName}) => async (dispatch) => {
    try {
     
      
      dispatch({ type: download_asbuilt_document_request });

      const { data } = await axios.post(`/asbuiltDocuments/download`, {
        name,category,projectName,fileName
      },{responseType:"blob"});
      dispatch({
        type: download_asbuilt_document_sucess,
        payload:{data:data,fileName} || null,
      });
    } catch (error) {
      dispatch({
        type: download_asbuilt_document_fail,
        payload: error?.response?.data?.error || error.message,
      });
    }
  };






const  getStartEndDateAndLabel=(dateRange)=>{
  let label;
      let startDate, endDate;
      if (dateRange["year"] && dateRange["endDataValue"]) {
        startDate = startYearToTimeStamps(dateRange["startDataValue"]);
        endDate = endYearToTimeStamps(dateRange["endDataValue"]);
        label = "Year";
      } else if (dateRange["month"] && dateRange["endDataValue"]) {
        startDate = startMonthToTimeStamps(
          dateRange["yearDataValue"],
          convertMonthToNumber(dateRange["startDataValue"])
        );
        endDate = endMonthToTimeStamps(
          dateRange["yearDataValue"],
          convertMonthToNumber(dateRange["endDataValue"])
        );
        label = "Month";
      } else if (dateRange["day"] && dateRange["endDataValue"]) {
        startDate = startDayToTimeStamps(
          dateRange["yearDataValue"],
          convertMonthToNumber(dateRange["monthDataValue"]),
          dateRange["startDataValue"]
        );
        endDate = endDayToTimeStamps(
          dateRange["yearDataValue"],
          convertMonthToNumber(dateRange["monthDataValue"]),
          dateRange["endDataValue"]
        );
        label = "Day";
      }

      return{
        startDate,
        endDate,label
      }
}



  
