import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SpinLoader from "../../../../components/SpinLoader";

/* ##### Heatmap Component  ##### */

const Heatmap = () => {
  const { heatmapData, loading } = useSelector((state) => state?.heatmapData);
  const dispatch = useDispatch();
  const location = useLocation();

  /* ###  Function To Get Inverter Number from URL   ### */

  const inverter = location.pathname?.split("/")[4];

  /* ###  End of Function To Get Inverter Number from URL   ### */

  useEffect(() => {
    // dispatch(HeatMapData(inverter[inverter?.length - 1]));
  }, [dispatch, inverter]);

  return (
    <>
      {loading ? (
        <SpinLoader />
      ) : (
        <Grid
          container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          textAlign={"center"}
        >
          <Grid
            item
            sx={{ boxShadow: 2 }}
            xl={11.8}
            lg={11.8}
            md={11.8}
            sm={11.8}
            xs={11.8}
            style={{
              background:
                "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
              // borderRadius: "8px",
              margin: "1vmax auto",
              padding: "1vmax",
            }}
          >
            <Grid
              item
              lg={12}
              sx={{
                position: "relative",
                backgroundColor: "white",
                padding: "1rem 0",
                height: "85vh",
                overflowX: "auto",
                overflowY: "hidden",
              }}
            >
              <h3
                style={{
                  position: "sticky",
                  top: "0",
                  left: "0",
                  right: "0",
                  padding: "0 1rem",
                  margin: "0",
                  width: "100%",
                }}
              >
                HeatMap Analysis For Inverter{inverter[inverter?.length - 1]}
              </h3>
              <Chart
                style={{
                  margin: "auto",
                  width: "95%",
                  minWidth: "500px",
                }}
                height={"95%"}
                type="heatmap"
                options={{
                  tooltip: {
                    style: {},
                  },

                  plotOptions: {
                    heatmap: {
                      distributed: true,
                      shadeIntensity: 1,
                      // reverseNegativeShade:false,
                      // shadeIntensity: 0.5,
                      // radius: 0,
                      // useFillColorAsStroke: true,
                      colorScale: {
                        ranges: [
                          {
                            from: -30,
                            to: -15,
                            name: "low",
                            color: "#00A100",
                          },
                          {
                            from: -14,
                            to: -12,
                            name: "medium",
                            color: "#128FD9",
                          },
                          {
                            from: -11,
                            to: -9,
                            name: "high",
                            color: "#FFB200",
                          },
                          {
                            from: -8,
                            to: -5,
                            name: "extreme",
                            color: "#FF0000",
                          },
                        ],
                      },
                    },
                  },
                  xaxis: {
                    labels: {
                      style: {
                        fontSize: "0.8rem",
                        fontWeight: "600",
                        fontFamily:
                          "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                      },
                    },
                  },
                  yaxis: {
                    labels: {
                      style: {
                        fontSize: "0.8rem",
                        fontWeight: "600",
                        fontFamily:
                          "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                      },
                    },
                  },
                  grid: {
                    show: true,
                    borderColor: "red",
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  // stroke: {
                  //     width: 5
                  // },

                  // title: {
                  //     text: 'HeatMap Chart with Color Range'
                  // },
                }}
                series={heatmapData?.data || []}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Heatmap;
