 
/* #####  Color Codes Array for Graph  ##### */
 
 const colors1 = ["#FEB019", "#304758", "#008FFB", "#00E396", "#444444", "#FEB019"]
 const colors2 = ['rgb(254, 176, 25)', 'rgb(48, 71, 88)', 'rgb(0, 143, 251)' ,'rgb(0, 227, 150)', 'rgb(68, 68, 68)', 'rgb(254, 176, 25)']
 const colors3=["#00af95","#a4c126","#51db57","#f3bc00","#0075a4","#e76f51","#468189","#3c096c","#5e60ce","#55286f","#b842b8","#00cecb"]

module.exports={
    colors1,
    colors2,
    colors3
}
