// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width:900px) {
.select-date-range-container{
    overflow: auto;
}

    
}`, "",{"version":3,"sources":["webpack://./src/modules/countries/countrymodule.css"],"names":[],"mappings":"AAAA;AACA;IACI,cAAc;AAClB;;;AAGA","sourcesContent":["@media screen and (max-width:900px) {\r\n.select-date-range-container{\r\n    overflow: auto;\r\n}\r\n\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
