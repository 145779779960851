import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SpinLoader from "../../../../components/SpinLoader";
import Printer from "../../../../components/Printer";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis,
  Area,
  AreaChart,
  ResponsiveContainer,
} from "recharts";
import { saveAs } from "file-saver";
import { CSVLink } from "react-csv";
import "./Detailed.css";
import CustomizeTootip from "../../../../components/CustomizeTootip";
import { colors1, colors3 } from "../../../../colors/color";
import CustomizeLegend from "../../../../components/CustomizeLegend";
import { exportComponentAsJPEG } from "react-component-export-image";
import { AxisLabel, saveToSvg } from "../../../../constants/Utils";
import Printer2 from "../../../../components/Printer2";
import PvsystActualGTI from "./PvsystActualGTI";
import PvsystActualGHI from "./PvsystActualGHI";
import { NetNormalisedShortFall } from "./NetNormalisedShortFall";
import PvsystContractual from "./PvsystContractual";
import {
  GHIGTIAction,
  netNormalisedEnergyShortfallAction,
  pvsystActualTempAction,
  pvsystContractualEnergyAction,
} from "../../../../actions/energyAction";
import GraphLoader from "../../../../components/GraphLoader";

const Detailed = ({
  selectedLebel,
  scbsmbDetail,
  setscbsmbDetail,
  databaseStr,
}) => {
  const { GHIGTI, loading } = useSelector((state) => state.GHIGTI);
  const { pvsystActualTemp, loading2 } = useSelector(
    (state) => state.pvsystActualTemp
  );
  const { pvsystContractualEnergy, loading3 } = useSelector(
    (state) => state.pvsystContractualEnergy
  );
  const { netNormalisedEnergyShortfall, loading4 } = useSelector(
    (state) => state.netNormalisedEnergyShortfall
  );
  const [showPVsystContractualData, setShowPVsystContractualData] = useState({
    pvsystEnergy: true,
    contractualEnergy: true,
    all: true,
  });

  /* ###   Initialize State to animate Graph Bars and Lines   ### */

  const [showNetNormalisedShortfallData, setShowNetNormalisedShortfallData] =
    useState({
      netEnergy: true,
      normalisedEnergy: true,
      shortfall: true,
      all: true,
    });

  /* ###   Initialize State to animate Graph Bars and Lines   ### */

  /* ###   Initialize State to animate Graph Bars and Lines   ### */

  const [showPvsystActualGHI, setShowPvsystActualGHI] = useState({
    PvsystGHI: true,
    ActualGHI: true,
    PvsystVsActualGHI: true,
    all: true,
  });

  /* ###   Initialize State to animate progress bar   ### */

  const [progressValueAnimate, setProgressValueAnimate] = useState({
    progress1: false,
    progress2: false,
    progress3: false,
    progress4: false,
    progress5: false,
    progress6: false,
  });

  /* ###   Reference to download graph data in CSV format   ### */

  const downloadRef1 = useRef(null);
  const downloadRef2 = useRef(null);
  const downloadRef3 = useRef(null);
  const downloadRef4 = useRef(null);

  /* ###   Reference for Graph container TO Download In Jpg and Svg format   ### */

  const graphRef1 = useRef(null);
  const graphRef2 = useRef(null);
  const graphRef3 = useRef(null);
  const graphRef4 = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedLebel.endDataValue && databaseStr) {
      dispatch(GHIGTIAction(selectedLebel, databaseStr));
      dispatch(pvsystActualTempAction(selectedLebel, databaseStr));
      dispatch(pvsystContractualEnergyAction(selectedLebel, databaseStr));
      dispatch(netNormalisedEnergyShortfallAction(selectedLebel, databaseStr));
    }
    if (!GHIGTI && databaseStr) {
      dispatch(GHIGTIAction(selectedLebel, databaseStr));
    }
    if (!pvsystActualTemp && databaseStr) {
      dispatch(pvsystActualTempAction(selectedLebel, databaseStr));
    }
    if (!pvsystContractualEnergy && databaseStr) {
      dispatch(pvsystContractualEnergyAction(selectedLebel, databaseStr));
    }
    if (!netNormalisedEnergyShortfall && databaseStr) {
      dispatch(netNormalisedEnergyShortfallAction(selectedLebel, databaseStr));
    }
  }, [dispatch, selectedLebel, databaseStr]);



  return (
    <>
      {
        // loading ? (
        //   <GraphLoader/>
        // ) :
        <Grid
          container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          textAlign={"center"}
        >
          {/* ###   Detailed Container 1 Start   ### */}

          <Grid
            sx={{ boxShadow: 2 }}
            item
            xl={11.8}
            lg={11.8}
            md={11.8}
            sm={11.8}
            xs={11.8}
            style={{
              boxSizing: "border-box",
              borderWidth: "3px",
              // borderRadius: "8px",
              margin: "0 auto",
              padding: "1vmax",
            }}
            className="detail_overview_container"
          >
            <div className="detail_overview_1">
              {loading ? (
                <div style={{ height: "100%", width: "100%" }}>
                  <GraphLoader />
                </div>
              ) : (
                <>
                  <div>
                    <div>
                      <div>
                        <progress
                          className="progress_1"
                          style={{
                            accentColor: "green",
                            borderRadius: "14px",
                            border: progressValueAnimate.progress1
                              ? "3px solid red"
                              : "none",
                          }}
                          value={
                            parseFloat(
                              GHIGTI?.reduce(
                                (acc, curr) => acc + curr["GII (KWh/m^2)"],
                                0
                              )
                            ).toFixed(2) || 0
                          }
                          max={
                            Math.ceil(
                              GHIGTI?.reduce(
                                (acc, curr) => acc + curr["GII (KWh/m^2)"],
                                0
                              )
                            ) + 100
                          }
                        />
                      </div>
                      <div>
                        <span>PVsyst GTI (kWh/m^2)</span>
                        <span
                          style={{
                            position: "absolute",
                            left: `${
                              (parseFloat(
                                GHIGTI?.reduce(
                                  (acc, curr) => acc + curr["GII (KWh/m^2)"],
                                  0
                                )
                              ).toFixed(2) *
                                100) /
                                (Math.ceil(
                                  GHIGTI?.reduce(
                                    (acc, curr) => acc + curr["GII (KWh/m^2)"],
                                    0
                                  )
                                ) +
                                  100) -
                              3
                            }%`,
                            bottom: "60%",
                          }}
                        >
                          <i class="fa-solid fa-caret-up"></i>
                        </span>

                        <span>
                          {parseFloat(
                            GHIGTI?.reduce(
                              (acc, curr) => acc + curr["GII (KWh/m^2)"],
                              0
                            )
                          ).toFixed(2) || 0}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <progress
                          className="progress_2"
                          style={{
                            accentColor: "green",
                            borderRadius: "14px",
                            border: progressValueAnimate.progress2
                              ? "3px solid black"
                              : "none",
                          }}
                          value={parseFloat(
                            GHIGTI?.reduce(
                              (acc, curr) => acc + curr["Actual GII (kWh/m^2)"],
                              0
                            )
                          ).toFixed(2)}
                          max={
                            Math.ceil(
                              GHIGTI?.reduce(
                                (acc, curr) =>
                                  acc + curr["Actual GII (kWh/m^2)"],
                                0
                              )
                            ) + 100
                          }
                        />
                      </div>
                      <div>
                        <span>Actual GTI (kWh/m^2)</span>
                        <span
                          style={{
                            position: "absolute",
                            left: `${
                              (parseFloat(
                                GHIGTI?.reduce(
                                  (acc, curr) =>
                                    acc + curr["Actual GII (kWh/m^2)"],
                                  0
                                )
                              ).toFixed(2) *
                                100) /
                                (Math.ceil(
                                  GHIGTI?.reduce(
                                    (acc, curr) =>
                                      acc + curr["Actual GII (kWh/m^2)"],
                                    0
                                  )
                                ) +
                                  100) -
                              3
                            }%`,
                            bottom: "60%",
                          }}
                        >
                          <i class="fa-solid fa-caret-up"></i>
                        </span>
                        <span>
                          {parseFloat(
                            GHIGTI?.reduce(
                              (acc, curr) => acc + curr["Actual GII (kWh/m^2)"],
                              0
                            )
                          ).toFixed(2) || 0}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <progress
                          className="progress_3"
                          style={{ accentColor: "green" }}
                          value={
                            // curr["GII (KWh/m^2)"],
                            // curr["Actual GII (kWh/m^2)"],
                            // acc
                            parseFloat(
                              ((parseFloat(
                                GHIGTI?.reduce(
                                  (acc, curr) => acc + curr["GII (KWh/m^2)"],
                                  0
                                )
                              ).toFixed(2) -
                                parseFloat(
                                  GHIGTI?.reduce(
                                    (acc, curr) =>
                                      acc + curr["Actual GII (kWh/m^2)"],
                                    0
                                  )
                                ).toFixed(2)) *
                                100) /
                                parseFloat(
                                  GHIGTI?.reduce(
                                    (acc, curr) => acc + curr["GII (KWh/m^2)"],
                                    0
                                  )
                                ).toFixed(2)
                            ).toFixed(2) || 0
                          }
                          max="100"
                        />
                      </div>
                      <div>
                        <span>Excess/Shortfall (%)</span>
                        <span
                          style={{
                            position: "absolute",
                            left: `${
                              (parseFloat(
                                ((parseFloat(
                                  GHIGTI?.reduce(
                                    (acc, curr) => acc + curr["GII (KWh/m^2)"],
                                    0
                                  )
                                ).toFixed(2) -
                                  parseFloat(
                                    GHIGTI?.reduce(
                                      (acc, curr) =>
                                        acc + curr["Actual GII (kWh/m^2)"],
                                      0
                                    )
                                  ).toFixed(2)) *
                                  100) /
                                  parseFloat(
                                    GHIGTI?.reduce(
                                      (acc, curr) =>
                                        acc + curr["GII (KWh/m^2)"],
                                      0
                                    )
                                  ).toFixed(2)
                              ).toFixed(2) || 0) - 3
                            }%`,
                            bottom: "60%",
                          }}
                        >
                          <i class="fa-solid fa-caret-up"></i>
                        </span>
                        <span>
                          {parseFloat(
                            ((parseFloat(
                              GHIGTI?.reduce(
                                (acc, curr) => acc + curr["GII (KWh/m^2)"],
                                0
                              )
                            ).toFixed(2) -
                              parseFloat(
                                GHIGTI?.reduce(
                                  (acc, curr) =>
                                    acc + curr["Actual GII (kWh/m^2)"],
                                  0
                                )
                              ).toFixed(2)) *
                              100) /
                              parseFloat(
                                GHIGTI?.reduce(
                                  (acc, curr) => acc + curr["GII (KWh/m^2)"],
                                  0
                                )
                              ).toFixed(2)
                          ).toFixed(2) || 0}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <span>
                        {parseFloat(
                          GHIGTI?.reduce(
                            (acc, curr) => acc + curr["GII (KWh/m^2)"],
                            0
                          )
                        ).toFixed(2)}
                      </span>
                      <span>PVsyst GTI (kWh/m^2) </span>
                      <div style={{ width: "80%", height: 30 }}>
                        <ResponsiveContainer>
                          <ComposedChart
                            margin={{
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                            }}
                            data={GHIGTI || []}
                          >
                            <XAxis dataKey="name" hide />
                            {/* <Tooltip/> */}
                            {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["PVsyst GTI"]} />} /> */}
                            <Bar
                              barSize={4}
                              radius={14}
                              dataKey="GII (KWh/m^2)"
                              fill="#8884d8"
                            />
                          </ComposedChart>
                        </ResponsiveContainer>
                      </div>
                      <span
                        className="progress_button_1"
                        onMouseEnter={() =>
                          setProgressValueAnimate({
                            ...progressValueAnimate,
                            progress1: true,
                          })
                        }
                        onMouseOut={() =>
                          setProgressValueAnimate({
                            ...progressValueAnimate,
                            progress1: false,
                          })
                        }
                      >
                        View
                      </span>
                    </div>
                    <div>
                      <span>
                        {parseFloat(
                          GHIGTI?.reduce(
                            (acc, curr) => acc + curr["Actual GII (kWh/m^2)"],
                            0
                          )
                        ).toFixed(2)}
                      </span>
                      <span>Actual GTI (kWh/m^2) </span>
                      <div style={{ width: "80%", height: 30 }}>
                        <ResponsiveContainer>
                          <ComposedChart
                            margin={{
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                            }}
                            data={GHIGTI || []}
                          >
                            <XAxis dataKey="name" hide />
                            {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["Actual GTI"]} />} /> */}
                            <Bar
                              barSize={4}
                              radius={14}
                              dataKey="Actual GII (kWh/m^2)"
                              fill="#8884d8"
                            />
                          </ComposedChart>
                        </ResponsiveContainer>
                      </div>
                      <span
                        onMouseEnter={() =>
                          setProgressValueAnimate({
                            ...progressValueAnimate,
                            progress2: true,
                          })
                        }
                        onMouseOut={() =>
                          setProgressValueAnimate({
                            ...progressValueAnimate,
                            progress2: false,
                          })
                        }
                        className="progress_button_2"
                      >
                        View
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="detail_overview_1">
              {loading ? (
                <div style={{ height: "100%", width: "100%" }}>
                  <GraphLoader />
                </div>
              ) : (
                <>
                  <div>
                    <div>
                      <div>
                        <progress
                          className="progress_4"
                          style={{
                            accentColor: "green",
                            borderRadius: "14px",
                            border: progressValueAnimate.progress4
                              ? "3px solid red"
                              : "none",
                          }}
                          value={parseFloat(
                            GHIGTI?.reduce(
                              (acc, curr) => acc + curr["GHI (KWh/m^2)"],
                              0
                            )
                          ).toFixed(2)}
                          max={
                            Math.ceil(
                              GHIGTI?.reduce(
                                (acc, curr) => acc + curr["GHI (KWh/m^2)"],
                                0
                              )
                            ) + 100 || 0
                          }
                        />
                      </div>
                      <div>
                        <span>PVsyst GHI (kWh/m^2)</span>
                        <span
                          style={{
                            position: "absolute",
                            left: `${
                              (parseFloat(
                                GHIGTI?.reduce(
                                  (acc, curr) => acc + curr["GHI (KWh/m^2)"],
                                  0
                                )
                              ).toFixed(2) *
                                100) /
                                Math.ceil(
                                  GHIGTI?.reduce(
                                    (acc, curr) => acc + curr["GHI (KWh/m^2)"],
                                    0
                                  ) + 100
                                ) -
                              3
                            }%`,
                            bottom: "60%",
                          }}
                        >
                          <i class="fa-solid fa-caret-up"></i>
                        </span>
                        <span>
                          {parseFloat(
                            GHIGTI?.reduce(
                              (acc, curr) => acc + curr["GHI (KWh/m^2)"],
                              0
                            )
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <progress
                          className="progress_5"
                          style={{
                            accentColor: "green",
                            borderRadius: "14px",
                            border: progressValueAnimate.progress5
                              ? "3px solid black"
                              : "none",
                          }}
                          value={
                            parseFloat(
                              GHIGTI?.reduce(
                                (acc, curr) =>
                                  acc + curr["Actual GHI (kWh/m^2)"],
                                0
                              )
                            ).toFixed(2) || 0
                          }
                          max={
                            Math.ceil(
                              GHIGTI?.reduce(
                                (acc, curr) =>
                                  acc + curr["Actual GHI (kWh/m^2)"],
                                0
                              )
                            ) + 100 || 0
                          }
                        />
                      </div>
                      <div>
                        <span>Actual GHI (kWh/m^2)</span>
                        {/* <span style={{fontSize:"1.2rem"}}><ArrowDropUpIcon  /></span> */}
                        <span
                          style={{
                            position: "absolute",
                            left: `${
                              (parseFloat(
                                GHIGTI?.reduce(
                                  (acc, curr) =>
                                    acc + curr["Actual GHI (kWh/m^2)"],
                                  0
                                )
                              ).toFixed(2) *
                                100) /
                                (Math.ceil(
                                  GHIGTI?.reduce(
                                    (acc, curr) =>
                                      acc + curr["Actual GHI (kWh/m^2)"],
                                    0
                                  )
                                ) +
                                  100) -
                                3 || 0
                            }%`,
                            bottom: "60%",
                          }}
                        >
                          <i class="fa-solid fa-caret-up"></i>
                        </span>
                        <span>
                          {parseFloat(
                            GHIGTI?.reduce(
                              (acc, curr) => acc + curr["Actual GHI (kWh/m^2)"],
                              0
                            )
                          ).toFixed(2) || 0}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <progress
                          className="progress_3"
                          style={{ accentColor: "blue" }}
                          value={
                            parseFloat(
                              ((parseFloat(
                                GHIGTI?.reduce(
                                  (acc, curr) => acc + curr["GHI (KWh/m^2)"],
                                  0
                                )
                              ).toFixed(2) -
                                parseFloat(
                                  GHIGTI?.reduce(
                                    (acc, curr) =>
                                      acc + curr["Actual GHI (kWh/m^2)"],
                                    0
                                  )
                                ).toFixed(2)) *
                                100) /
                                parseFloat(
                                  GHIGTI?.reduce(
                                    (acc, curr) => acc + curr["GHI (KWh/m^2)"],
                                    0
                                  )
                                ).toFixed(2)
                            ).toFixed(2) || 0
                          }
                          max="100"
                        />
                      </div>
                      <div>
                        <span>Excess/ShortFall (%)</span>
                        {/* <span style={{fontSize:"1.2rem"}}><ArrowDropUpIcon  /></span> */}
                        <span
                          style={{
                            position: "absolute",
                            left: `${
                              parseFloat(
                                ((parseFloat(
                                  GHIGTI?.reduce(
                                    (acc, curr) => acc + curr["GHI (KWh/m^2)"],
                                    0
                                  )
                                ).toFixed(2) -
                                  parseFloat(
                                    GHIGTI?.reduce(
                                      (acc, curr) =>
                                        acc + curr["Actual GHI (kWh/m^2)"],
                                      0
                                    )
                                  ).toFixed(2)) *
                                  100) /
                                  parseFloat(
                                    GHIGTI?.reduce(
                                      (acc, curr) =>
                                        acc + curr["GHI (KWh/m^2)"],
                                      0
                                    )
                                  ).toFixed(2)
                              ).toFixed(2) - 3
                            }%`,
                            bottom: "60%",
                          }}
                        >
                          <i class="fa-solid fa-caret-up"></i>
                        </span>
                        <span>
                          {parseFloat(
                            ((parseFloat(
                              GHIGTI?.reduce(
                                (acc, curr) => acc + curr["GHI (KWh/m^2)"],
                                0
                              )
                            ).toFixed(2) -
                              parseFloat(
                                GHIGTI?.reduce(
                                  (acc, curr) =>
                                    acc + curr["Actual GHI (kWh/m^2)"],
                                  0
                                )
                              ).toFixed(2)) *
                              100) /
                              parseFloat(
                                GHIGTI?.reduce(
                                  (acc, curr) => acc + curr["GHI (KWh/m^2)"],
                                  0
                                )
                              ).toFixed(2)
                          ).toFixed(2) || 0}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <span>
                        {parseFloat(
                          GHIGTI?.reduce(
                            (acc, curr) => acc + curr["GHI (KWh/m^2)"],
                            0
                          )
                        ).toFixed(2) || 0}
                      </span>
                      <span>PVSYST GHI (kWh/m^2)</span>
                      <div style={{ width: "80%", height: 30 }}>
                        <ResponsiveContainer>
                          <ComposedChart
                            margin={{
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                            }}
                            data={GHIGTI || []}
                          >
                            <XAxis dataKey="name" hide />
                            {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["PVsyst GHI"]} />} /> */}
                            <Bar
                              barSize={4}
                              radius={14}
                              dataKey="GHI (KWh/m^2)"
                              fill="#8884d8"
                            />
                          </ComposedChart>
                        </ResponsiveContainer>
                      </div>
                      <span
                        className="progress_button_1"
                        onMouseEnter={() =>
                          setProgressValueAnimate({
                            ...progressValueAnimate,
                            progress4: true,
                          })
                        }
                        onMouseOut={() =>
                          setProgressValueAnimate({
                            ...progressValueAnimate,
                            progress4: false,
                          })
                        }
                      >
                        View
                      </span>
                    </div>
                    <div>
                      <span>
                        {parseFloat(
                          GHIGTI?.reduce(
                            (acc, curr) => acc + curr["Actual GHI (kWh/m^2)"],
                            0
                          )
                        ).toFixed(2) || 0}
                      </span>
                      <span>Actual GHI (kWh/m^2) </span>
                      <div style={{ width: "80%", height: 30 }}>
                        <ResponsiveContainer>
                          <ComposedChart
                            margin={{
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                            }}
                            data={GHIGTI || []}
                          >
                            <XAxis dataKey="name" hide />
                            {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["Actual GHI"]} />} /> */}
                            <Bar
                              barSize={4}
                              radius={14}
                              dataKey="Actual GHI (kWh/m^2)"
                              fill="#8884d8"
                            />
                          </ComposedChart>
                        </ResponsiveContainer>
                      </div>
                      <span
                        onMouseEnter={() =>
                          setProgressValueAnimate({
                            ...progressValueAnimate,
                            progress5: true,
                          })
                        }
                        onMouseOut={() =>
                          setProgressValueAnimate({
                            ...progressValueAnimate,
                            progress5: false,
                          })
                        }
                        className="progress_button_2"
                      >
                        View
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>

            {loading2 ? (
              <div
                style={{
                  minHeight: "220px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <GraphLoader />
              </div>
            ) :
             (
              <div className="detail_overview_2">
                <>
                  <div style={{ width: "100%", height: 100 }}>
                    {
                      pvsystActualTemp?.length>0?

                    <ResponsiveContainer>
                      <AreaChart
                        margin={{
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                        }}
                        data={pvsystActualTemp || []}
                      >
                        <XAxis dataKey="name" hide />
                        <YAxis
                          type="number"
                          hide
                          dataKey={() =>
                            Math.ceil(
                              pvsystActualTemp?.reduce(
                                (acc, curr, index) =>
                                  Math.max(
                                    curr.pvsystTemp,
                                    curr.actualTemp,
                                    acc
                                  ),
                                -Infinity
                              )
                            )
                          }
                          domain={[
                            Math.floor(
                              pvsystActualTemp?.reduce(
                                (acc, curr, index) =>
                                  Math.min(
                                    curr.pvsystTemp,
                                    curr.actualTemp,
                                    acc
                                  ),
                                Infinity
                              )
                            ),
                            "dataMax",
                          ]}
                        />
                        <Area
                          dataKey="pvsystTemp"
                          stroke="rgb(11, 165, 119)"
                          strokeWidth={0.2}
                          fill="rgb(20, 213, 149)"
                        />
                        <Area
                          dataKey="actualTemp"
                          stroke="rgb(11, 165, 119)"
                          strokeWidth={0.2}
                          fill="rgb(91, 248, 201)"
                        />
                        <Tooltip
                          content={
                            <CustomizeTootip
                              active={false}
                              payload={[]}
                              label={""}
                              TooltipValues={[
                                "PVsyst Module Temp",
                                "Actual Module Temp",
                              ]}
                            />
                          }
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                    :
                    <div style={{height:"100%",width:"100%", display:"flex",alignItems:"center",justifyContent:"center"}}>
                      <span>Data Not Available</span>

                    </div>
                    }

                  </div>
                  <div>
                    <div>
                      <span>
                        {!pvsystActualTemp?.length?"": Number(
                          pvsystActualTemp?.reduce(
                            (acc, curr) => acc + Number(curr["pvsystTemp"]),
                            0
                          ) / pvsystActualTemp?.length
                        ).toFixed(2) || 0}
                      </span>
                      <span style={{ fontSize: "0.6rem" }}>
                        PVsyst Ambient Temp (℃)
                      </span>
                    </div>
                    <span
                      style={{
                        width: "2px",
                        background: "rgb(166, 176, 173)",
                        height: "90%",
                      }}
                    ></span>
                    <div>
                      <span>
                        {!pvsystActualTemp?.length?"": Number(
                          pvsystActualTemp?.reduce(
                            (acc, curr) => acc + Number(curr["actualTemp"]),
                            0
                          ) / pvsystActualTemp?.length
                        )?.toFixed(2) || 0}
                      </span>
                      <span style={{ fontSize: "0.6rem" }}>
                        Actual Ambient Temp (℃)
                      </span>
                    </div>
                  </div>
                </>
              </div>
            )}
          </Grid>

          {/* ###   Detailed Container 1 end ### */}

          {/* ###   Detailed Container 2 start ### */}

          <Grid
            sx={{ boxShadow: 2 }}
            item
            xl={11.8}
            lg={11.8}
            md={11.8}
            sm={11.8}
            xs={11.8}
            style={{
              background:
                "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
              boxSizing: "border-box",
              borderWidth: "3px",
              // borderRadius: "8px",
              margin: "1vmax auto 0",
              padding: "1vmax",
            }}
          >
            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={"1vmax"}
            >
              <Grid
                item
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  position: "relative",
                  backgroundColor: "#fff",
                  padding: "1vmax",
                  overflow: "auto",
                  minHeight: "40vh",
                }}
              >
                <PvsystActualGTI GHIGTI={GHIGTI} loading={loading} />
              </Grid>
              <Grid
                item
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  position: "relative",
                  backgroundColor: "#fff",
                  padding: "1vmax",
                  overflow: "auto",
                }}
              >
                <PvsystActualGHI GHIGTI={GHIGTI} loading={loading} />
              </Grid>
            </Grid>
          </Grid>

          {/* ###   Detailed Container 2 end ### */}

          {/* ###   Detailed Container 3 start ### */}

          <Grid
            sx={{ boxShadow: 2 }}
            item
            xl={11.8}
            lg={11.8}
            md={11.8}
            sm={11.8}
            xs={11.8}
            style={{
              background:
                "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
              boxSizing: "border-box",
              borderWidth: "3px",
              // borderRadius: "8px",
              margin: "1vmax auto",
              padding: "1vmax",
            }}
          >
            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={"1vmax"}
            >
              <Grid
                item
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  position: "relative",
                  backgroundColor: "#fff",
                  padding: "1vmax",
                  overflow: "auto",
                }}
              >
                <NetNormalisedShortFall
                  netNormalisedEnergyShortfall={netNormalisedEnergyShortfall}
                  loading={loading4}
                />
              </Grid>
              <Grid
                item
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  position: "relative",
                  backgroundColor: "#fff",
                  padding: "1vmax",
                  overflow: "auto",
                }}
              >
                <PvsystContractual
                  pvsystContractualEnergy={pvsystContractualEnergy}
                  loading={loading3}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* ###   Detailed Container 3 end ### */}
        </Grid>
      }
    </>
  );
};

export default Detailed;
