import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../Headers/CountryHeader.css";
import PageURL from "../../../../constants/PageURL";
import InverterEfficiency from "../inverterEfficiency/InverterEfficiency";
import SCBSMB from "../SCBSMB/SCBSMB";
import ProjOverView from "../projectOverView/ProjOverView";
import PresCripModel from "../prescriptiveModel/PresCripModel";
import LossFlow from "./LossFlow";
import Detailed from "./Detailed";
import Heatmap from "../SCBSMB/Heatmap";
import FaultTree from "../prescriptiveModel/FaultTree";
import GenOverView from "../overviewDetail/GenOverView";
import ProjectDetailBody from "../ProjectDetails/ProjectDetailBody";

/* ##### Diagnostic component which having all the country related components  ##### */

const Diagonistic = ({databaseStr, selectedLebel, sestSelectedLebel, selectedValue,setSelectedValue,scbsmbDetail,setscbsmbDetail}) => {
  const location = useLocation();
  const navigate=useNavigate()

  // useEffect(()=>{
  //   console.log("hre1")

  //   if(!selectedValue.country || !selectedValue.partnerName || !selectedValue.projectName){
  //     navigate(PageURL.PROJECT_DETAILS)
  //     console.log("hre2")
  //   }
  // },[selectedValue,navigate,location.pathname])
 
  return (
    <>
      {/* <CountryHeader2 /> */}

      {location.pathname === PageURL.PROJECT_DETAILS && (
            <ProjectDetailBody />
          )}

         
          {location.pathname === PageURL.INDIA_PRES_MODEL_Fault_Tree && (
            <FaultTree />
          )}
          {location.pathname === PageURL.INDIA_PRES_MODEL_Loss_Flow && (
            <PresCripModel />
          )}
          {location.pathname === PageURL.INDIA_LOSS_FLOW && <LossFlow />}
      {location?.pathname === PageURL.INDIA_DIAGONISTIC_DETAILED && (
        <Detailed selectedLebel={selectedLebel} scbsmbDetail={scbsmbDetail} setscbsmbDetail={setscbsmbDetail} databaseStr={databaseStr}/>
      )}
      {location?.pathname === PageURL.INDIA_INVERTER_EFFICIENCY && (
        <InverterEfficiency selectedValue={selectedValue} selectedLebel={selectedLebel}  scbsmbDetail={scbsmbDetail} setscbsmbDetail={setscbsmbDetail} databaseStr={databaseStr} />
      )}
      {location?.pathname === PageURL.INDIA_GEN_SUMMARY && <GenOverView selectedValue={selectedValue} selectedLebel={selectedLebel}  scbsmbDetail={scbsmbDetail} setscbsmbDetail={setscbsmbDetail} databaseStr={databaseStr}/>}
      {/* {location?.pathname === PageURL.INDIA_GEN_SUMMARY && null} */}
      {location?.pathname === PageURL.INDIA_PROJ_OVERVIEW && <ProjOverView  databaseStr={databaseStr}/>}
      {location?.pathname === PageURL.INDIA_PRES_MODEL_Loss_Flow && (
        <PresCripModel />
      )}
      {location?.pathname === PageURL.INDIA_PRES_MODEL_Fault_Tree && (
        <FaultTree />
      )}
      {location?.pathname === PageURL.INDIA_INVERTER1_HEATMAP_DIAGRAM && (
        <Heatmap />
      )}
      {location?.pathname === PageURL.INDIA_INVERTER2_HEATMAP_DIAGRAM && (
        <Heatmap />
      )}
      {location?.pathname === PageURL.INDIA_INVERTER3_HEATMAP_DIAGRAM && (
        <Heatmap />
      )}
      {location?.pathname === PageURL.INDIA_INVERTER4_HEATMAP_DIAGRAM && (
        <Heatmap />
      )}
      {(location?.pathname === PageURL.INDIA_INVERTER1_SCB_SMB1 ||
        location?.pathname === PageURL.INDIA_INVERTER1_SCB_SMB2 ||
        location?.pathname === PageURL.INDIA_INVERTER1_SCB_SMB3 ||
        location?.pathname === PageURL.INDIA_INVERTER1_SCB_SMB4 ||
        location?.pathname === PageURL.INDIA_INVERTER1_SCB_SMB5 ||
        location?.pathname === PageURL.INDIA_INVERTER1_SCB_SMB6 ||
        location?.pathname === PageURL.INDIA_INVERTER1_SCB_SMB7 ||
        location?.pathname === PageURL.INDIA_INVERTER1_SCB_SMB8 ||
        location?.pathname === PageURL.INDIA_INVERTER1_SCB_SMB9 ||
        location?.pathname === PageURL.INDIA_INVERTER1_SCB_SMB10 ||
        location?.pathname === PageURL.INDIA_INVERTER1_SCB_SMB11 ||
        location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB1 ||
        location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB2 ||
        location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB3 ||
        location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB4 ||
        location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB5 ||
        location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB6 ||
        location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB7 ||
        location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB8 ||
        location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB9 ||
        location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB10 ||
        location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB11 ||
        location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB11 ||
        location?.pathname === PageURL.INDIA_INVERTER2_SCB_SMB12 ||
        location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB1 ||
        location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB2 ||
        location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB3 ||
        location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB4 ||
        location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB5 ||
        location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB6 ||
        location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB7 ||
        location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB8 ||
        location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB9 ||
        location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB10 ||
        location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB11 ||
        location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB11 ||
        location?.pathname === PageURL.INDIA_INVERTER3_SCB_SMB12 ||
        location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB1 ||
        location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB2 ||
        location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB3 ||
        location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB4 ||
        location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB5 ||
        location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB6 ||
        location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB7 ||
        location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB8 ||
        location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB9 ||
        location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB10 ||
        location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB11 ||
        location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB11 ||
        location.pathname===PageURL.SCBSMB_LOSS ||
        location?.pathname === PageURL.INDIA_INVERTER4_SCB_SMB12) && <SCBSMB selectedValue={selectedValue}setSelectedValue={setSelectedValue}  sestSelectedLebel={sestSelectedLebel} selectedLebel={selectedLebel}  scbsmbDetail={scbsmbDetail} setscbsmbDetail={setscbsmbDetail} databaseStr={databaseStr} />}

        
    </>
  );
};

export default Diagonistic;
