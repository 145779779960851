import React, { useEffect, useState } from "react";
import "./Login.css";
import Buttons from "../../../components/ButtonsComp";
import { useNavigate } from "react-router-dom";
import PageURL from "../../../constants/PageURL";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { clear_errors, resend_otp_reset, verify_otp_reset } from "../../../constants/dataConstants";
import { ResendOtp, verifyOtp } from "../../../actions/userActions";
import Loader from "../../../components/Loader";


/* ##### OtpVerify Component To Render OtpVerify Container  ##### */

const OtpComponent = (props) => {
  const navigate = useNavigate();
  const [otp, setotp] = useState("");
  const dispatch = useDispatch();

  const { isVerifiedOtp, error, isResendOtp, loading } = useSelector(state => state.user)
  let email = JSON.parse(localStorage.getItem("email"))

  useEffect(() => {
    if (error) {
      toast.error(error)
      dispatch({ type: clear_errors })
    }
    if (isVerifiedOtp) {
      toast.success("Otp verified successfully check your mail to get new password")
      dispatch({ type: verify_otp_reset })
      navigate(PageURL.BASE)
    }
    if (isResendOtp) {
      toast.success("Otp Re-sent sucessfully!")
      dispatch({ type: resend_otp_reset })
    }
  }, [navigate, dispatch, isVerifiedOtp, error, isResendOtp])


  return (
    <div style={{width:"100%"}} className="login-box">
      {loading && <Loader />}
      <h2 style={{ textAlign: "center" }}>Verify OTP</h2>
      <div  className="inputs" style={{width:"80%"}}>
        <input
          type="number"
          placeholder="Enter OTP"
          style={{
            textAlign: "center",
            fontWeight: "bolder",
            color: "black",
          }}
          value={otp}
          onChange={(e) => {
            setotp(e.target.value)
          }}
        />
      </div>
      <div style={{flexDirection:"row"}} className="buttons">
        <Buttons
          type="contained"
          buttonType="primary"
          buttonText="Resend OTP"
          onClick={() => dispatch(ResendOtp(email))}
        ></Buttons>
        <Buttons
          type="outlined"
          buttonType="secondary"
          buttonText="Enter OTP"
          onClick={() => dispatch(verifyOtp(otp, email))}
        ></Buttons>
      </div>
    </div>
  );
};

export default OtpComponent;
