// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scbsmb-responsive-container-1 {
  height: 60vh;
  width: 100%;
  min-width: 1000px;
  position: relative;
}

.scbsmb-responsive-container-2 {
  height: 60vh;
  width: 100%;
  min-width: 1000px;
  position: relative;
}

@media screen and (max-width: 1000px) {
  .scbsmb-responsive-container-1 {
  }
}

@media screen and (max-width: 800px) {
  .scbsmb-responsive-container-2 {
 /* min-width: 1500px; */
  }
}

`, "",{"version":3,"sources":["webpack://./src/modules/countries/components/SCBSMB/scbsmb.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE;EACA;AACF;;AAEA;EACE;CACD,uBAAuB;EACtB;AACF","sourcesContent":[".scbsmb-responsive-container-1 {\r\n  height: 60vh;\r\n  width: 100%;\r\n  min-width: 1000px;\r\n  position: relative;\r\n}\r\n\r\n.scbsmb-responsive-container-2 {\r\n  height: 60vh;\r\n  width: 100%;\r\n  min-width: 1000px;\r\n  position: relative;\r\n}\r\n\r\n@media screen and (max-width: 1000px) {\r\n  .scbsmb-responsive-container-1 {\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 800px) {\r\n  .scbsmb-responsive-container-2 {\r\n /* min-width: 1500px; */\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
