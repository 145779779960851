import React from "react";
import { Grid } from "@mui/material";

import PrescriptiveTable from "./PrescriptiveTable";
import PrescriptiveLoss from "./PrescriptiveLoss";

const PresCripModel = () => {
  return (
    <>
      <Grid
        container
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          padding: "1vmax 0",
        }}
      >
        <Grid
          className="prescriptive-container"
          sx={{ boxShadow: 2 }}
          container
          xl={11.8}
          lg={11.8}
          md={11.8}
          sm={11.8}
          xs={11.8}
          style={{
            margin: "0 auto",
            boxSizing: "border-box",
            borderWidth: "3px",
            padding: "1vmax",
            display: "flex",
            justifyContent: "center",
            gap: "1vmax",
            background:
              "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
          }}
        >
          <Grid
            className="prescriptive-container-item-1"
            item
            lg={5.9}
            md={5.9}
            sm={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "white",
            }}
          >
            <PrescriptiveTable />
          </Grid>
          <Grid
            item
            lg={5.9}
            md={5.9}
            sm={12}
            xs={12}
            style={{
              background: "white",
              position: "relative",
              overflow: "auto",
              padding: "1vmax",
            }}
            // className="prescriptive-container-item-2"
          >
            <PrescriptiveLoss />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PresCripModel;
