import React from 'react'
import './Loader.css'


/* Loder Component which is used in Login, signup, Forgotpassword and Otp verify component */

const Loader = () => {
    return (
        <div style={{ display: "flex", height: "100%", width: "100%", position: "absolute", background: "rgba(5, 45, 45, 0.968)", opacity: "0.5", alignItems: "center", justifyContent: "center", zIndex: 5 }}>
            {/* <span className="loader" ></span> */}
            <div className="module">
                <div className="throbber sun">
                    <div className="loading-container">
                        <div className="loader">
                            <div className="spoke-center"></div>
                            <div className="loading-spokes"> <div className="spoke-container"><div className="spoke"></div></div>
                                <div className="spoke-container"><div className="spoke"></div></div>
                                <div className="spoke-container"><div className="spoke"></div></div>
                                <div className="spoke-container"><div className="spoke"></div></div>
                                <div className="spoke-container"><div className="spoke"></div></div>
                                <div className="spoke-container"><div className="spoke"></div></div>
                                <div className="spoke-container"><div className="spoke"></div></div>
                                <div className="spoke-container"><div className="spoke"></div></div>
                            </div>
                        </div>
                    </div>
                </div></div>
        </div>



    )
}

export default Loader