import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { thunk } from "redux-thunk"
import { composeWithDevTools } from 'redux-devtools-extension'
import { allow_user, registred_users, user } from '../reducers/userReducers'
import { acLineLossActualPrReducer, asbuiltDocumentReducer, GHIGTIReducer, inverterEfficiencyReducer, netContractualEnergyShortfallReducer, netNormalisedEnergyShortfallReducer, pvsystActualTempReducer, pvsystContractualEnergyReducer, scbsmbLossReducer } from '../reducers/energyReducer'

/* ##### Creating Reducer using Combine Reducer  ##### */

const reducer = combineReducers({
 
  user: user,
  registred_users: registred_users,
  allow_user: allow_user,
 
  scbsmbLoss:scbsmbLossReducer,
  inverterEfficiency:inverterEfficiencyReducer,
  GHIGTI:GHIGTIReducer,
  pvsystActualTemp:pvsystActualTempReducer,
  pvsystContractualEnergy:pvsystContractualEnergyReducer,
  netNormalisedEnergyShortfall:netNormalisedEnergyShortfallReducer,
  netContractualEnergyShortfall:netContractualEnergyShortfallReducer,
  acLineLossActualPr:acLineLossActualPrReducer,
  asbuiltDocument:asbuiltDocumentReducer
})

/* #####  To Initialize Our Store Values to undefined When Dispathed an Action of Type "USER_LOGOUT"  ##### */

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return reducer(undefined, action)
  }
  return reducer(state, action)
}

const middleware = [thunk];
let initialState = {};

/* #####  Applying Middleware (Thunk) and Creating Redux Store  ##### */

// const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))
const store = createStore(rootReducer, initialState,applyMiddleware(...middleware))


export default store;


