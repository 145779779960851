import React, { forwardRef, useState } from "react";

/* ###  General Details Component  ### */

const GeneralDetails = ({title,url}) => {

  
  return (
    <div style={{ padding: "1vmax", display: "flex", flexDirection: "column" }}>
      <h1
        style={{
          textAlign: "center",
          color: "#122b4f",
          fontWeight: "bolder",
          padding: "0.5vmax 0",
        }}
      >
        Site Geo-location
      </h1>
      <iframe
        title={title}
        // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23258.29457300232!2d72.55990238121323!3d27.661948427447417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x393f7b9dd3ee4c3b%3A0xce7272da805b5ef3!2sNTPC%20300MW%20solar%20power%20plant%20NOKHRA!5e1!3m2!1sen!2sin!4v1727334081996!5m2!1sen!2sin"
        src={url}
        width="100%"
        height="450"
        loading="lazy"
      ></iframe>
    </div>
  );
};

export default GeneralDetails
