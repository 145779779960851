import React from "react";
import "./SmallCard.css";

/* ##### SmallCard Component In Project Overview Component ##### */

const SmallCard = (props) => {
  return (
    <div className="small-card-style">
        <h2 style={{ textAlign: "center", color: "#122b4f",fontSize:"3vmin",margin:"1vmin 0" }}>
          {props?.heading}
        </h2>
        {props?.body?.map((item, i) => {
          return (
            <div key={i} style={{margin:"1.5vmin 0"}}>
              <h5 style={{ textAlign: "center" ,fontSize:"2vmin"}}>{item}</h5>
            </div>
          );
        })}
        <span style={{margin:"1vmin 0"}}
        onClick={props?.onClick}
        className="view-button"
      >
        {props?.showExpanded ? `Reset` : `View`}
      </span>
    </div>
  );
};

export default SmallCard;
