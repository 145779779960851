import { Grid } from "@mui/material";
import "./FaultTree.css";
import { dataTree } from "../../../../constants/Data";

/* ##### FaultTree component  ##### */

const FaultTree = () => {
  const treeData = dataTree;
  return (
    <Grid
      container
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      textAlign={"center"}
    >
      <Grid
        sx={{ boxShadow: 2 }}
        item
        xl={11.8}
        lg={11.8}
        md={11.8}
        sm={11.8}
        xs={11.8}
        style={{
          boxSizing: "border-box",
          borderWidth: "3px",
          // borderRadius: "8px",
          margin: "0.5vmax auto",
          padding: "1vmax",
          background:
            "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          lg={12}
          sx={{
            background: "white",
            padding: "1vmax",
            maxWidth: "100%",
            overflow: "auto",
          }}
        >
             <h2
              style={{
                textAlign: "center",
                color: "black",
                position: "sticky",
                top: "0",
               left:"0",
               right:"0"
             
              }}
            >
              Fault Tree Analysis
            </h2>
          <div className="scroll-tree-container">
           
            <div className="tree">{treeRendering(treeData)}</div>
          </div>

          <div></div>
        </Grid>
      </Grid>
    </Grid>
  );
};
const treeRendering = (treeData) => {
  return (
    <>
      <ul>
        {treeData.map((item) => (
          <li className={item.text + item.id}>
            <div
              style={{
                backgroundColor: `${
                  item.id === 2 ||
                  item.id === 5 ||
                  item.id === 8 ||
                  item.id === 11 ||
                  item.id === 14
                    ? "#ffa907"
                    : item.id === 1
                    ? "#fbdb22"
                    : item.id === 3
                    ? "#fbd1af"
                    : "#befafb"
                }`,
                // position:item.text==="DC cables"?"relative":"static"
                // ,
                padding: "0.7vmax 0.1vmax",
                width:
                  item.id === 1
                    ? "5.5vmax"
                    : item.id === 2 ||
                      item.id === 5 ||
                      item.id === 8 ||
                      item.id === 11 ||
                      item.id === 14
                    ? "5.5vmax"
                    : item.id === 3
                    ? "5.5vmax"
                    : "5.5vmax",
                height: "3.5vmax",
              }}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                  fontSize: "0.8vmax",
                }}
              >
                {item.text}
              </span>{" "}
            </div>
            {item.children &&
              item.children.length &&
              treeRendering(item.children)}
          </li>
        ))}
      </ul>
    </>
  );
};

export default FaultTree;
