import {
  acLineLoss_actualPr_fail,
  acLineLoss_actualPr_request,
  acLineLoss_actualPr_sucess,
  asbuilt_document_fail,
  asbuilt_document_request,
  asbuilt_document_sucess,
  download_asbuilt_document_fail,
  download_asbuilt_document_request,
  download_asbuilt_document_reset,
  download_asbuilt_document_sucess,
  GHI_GTI_data_fail,
  GHI_GTI_data_request,
  GHI_GTI_data_sucess,
  inverter_efficiency_fail,
  inverter_efficiency_request,
  inverter_efficiency_sucess,
  net_contractual_shortfall_request,
  net_contractual_shortfall_sucess,
  net_normalised_shortfall_fail,
  net_normalised_shortfall_request,
  net_normalised_shortfall_sucess,
  pvsyst_actual_temp_fail,
  pvsyst_actual_temp_request,
  pvsyst_actual_temp_sucess,
  pvsyst_contractual_energy_fail,
  pvsyst_contractual_energy_request,
  pvsyst_contractual_energy_sucess,
  scbsmb_monthly_loss_fail,
  scbsmb_monthly_loss_request,
  scbsmb_monthly_loss_reset,
  scbsmb_monthly_loss_sucess,
} from "../constants/dataConstants";

export const scbsmbLossReducer = (
  state = { scbsmbLossMonthly: null, scbsmbLossYearly:null },
  action
) => {
  switch (action.type) {
    case scbsmb_monthly_loss_request:
      return {
        ...state,
        loading: true,
      };
    case scbsmb_monthly_loss_sucess:
      return {
        ...state,
        loading: false,
        scbsmbLossMonthly: action.payload,
      };
    case scbsmb_monthly_loss_fail:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case scbsmb_monthly_loss_reset:
        return{
            ...state,
            loading:false,
            error:null
        }
    default:
      return state;
  }
};


export const inverterEfficiencyReducer = (
  state = { inverterEfficiency: null },
  action
) => {
  switch (action.type) {
    case inverter_efficiency_request:
      return {
        ...state,
        loading: true,
      };
    case inverter_efficiency_sucess:
      return {
        ...state,
        loading: false,
        inverterEfficiency: action.payload,
      };
    case inverter_efficiency_fail:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state;
  }
};

export const GHIGTIReducer = (
  state = { GHIGTI: null },
  action
) => {
  switch (action.type) {
    case GHI_GTI_data_request:
      return {
        ...state,
        loading: true,
      };
    case GHI_GTI_data_sucess:
      return {
        ...state,
        loading: false,
        GHIGTI: action.payload,
      };
    case GHI_GTI_data_fail:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state;
  }
};

export const pvsystActualTempReducer = (
  state = { pvsystActualTemp: null },
  action
) => {
  switch (action.type) {
    case pvsyst_actual_temp_request:
      return {
        ...state,
        loading2: true,
      };
    case pvsyst_actual_temp_sucess:
      return {
        ...state,
        loading2: false,
        pvsystActualTemp: action.payload,
      };
    case pvsyst_actual_temp_fail:
      return {
        ...state,
        loading2: false,
        error: action.payload,
      }
    default:
      return state;
  }
};

export const pvsystContractualEnergyReducer = (
  state = { pvsystContractualEnergy: null },
  action
) => {
  switch (action.type) {
    case pvsyst_contractual_energy_request:
      return {
        ...state,
        loading3: true,
      };
    case pvsyst_contractual_energy_sucess:
      return {
        ...state,
        loading3: false,
        pvsystContractualEnergy: action.payload,
      };
    case pvsyst_contractual_energy_fail:
      return {
        ...state,
        loading3: false,
        error: action.payload,
      }
    default:
      return state;
  }
};


export const netNormalisedEnergyShortfallReducer = (
  state = { netNormalisedEnergyShortfall: null },
  action
) => {
  switch (action.type) {
    case net_normalised_shortfall_request:
      return {
        ...state,
        loading4: true,
      };
    case net_normalised_shortfall_sucess:
      return {
        ...state,
        loading4: false,
        netNormalisedEnergyShortfall: action.payload,
      };
    case net_normalised_shortfall_fail:
      return {
        ...state,
        loading4: false,
        error: action.payload,
      }
    default:
      return state;
  }
};


export const netContractualEnergyShortfallReducer = (
  state = { netContractualEnergyShortfall: null },
  action
) => {
  switch (action.type) {
    case net_contractual_shortfall_request:
      return {
        ...state,
        loading: true,
      };
    case net_contractual_shortfall_sucess:
      return {
        ...state,
        loading: false,
        netContractualEnergyShortfall: action.payload,
      };
    case net_normalised_shortfall_fail:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state;
  }
};


export const acLineLossActualPrReducer = (
  state = { acLineLossActualPr: null },
  action
) => {
  switch (action.type) {
    case acLineLoss_actualPr_request:
      return {
        ...state,
        loading2: true,
      };
    case acLineLoss_actualPr_sucess:
      return {
        ...state,
        loading2: false,
        acLineLossActualPr: action.payload,
      };
    case acLineLoss_actualPr_fail:
      return {
        ...state,
        loading2: false,
        error: action.payload,
      }
    default:
      return state;
  }
};

export const asbuiltDocumentReducer = (
  state = { asbuiltDocument: null,downloadAsbuiltDocument:null },
  action
) => {
  switch (action.type) {
    case asbuilt_document_request:
      return {
        ...state,
        loading: true,
      };
    case download_asbuilt_document_request:{
      return{
        ...state,
        loading2:true
      }
    }
    case asbuilt_document_sucess:
      return {
        ...state,
        loading: false,
        asbuiltDocument: action.payload,
      };
      case download_asbuilt_document_sucess:
        return{
        ...state,
        loading2:false,
        downloadAsbuiltDocument:action.payload
      }
    case asbuilt_document_fail:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

      case download_asbuilt_document_fail:
        return{
        ...state,
        loading2:false,
        error: action.payload,
      }

      case download_asbuilt_document_reset:
        return{
        ...state,
        loading2:false,
        downloadAsbuiltDocument:null
      }
    default:
      return state;
  }
};

