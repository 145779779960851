import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Dropdown = ({ label, listData, onchangeYear, value }) => {
  const [selectedButton, setSelectedButton] = useState(false);
  return (
    <>
      <button
        className={`${selectedButton || value ? "selected" : ""}`}
        onClick={() => setSelectedButton(!selectedButton)}
      >
        <span>
          <span>{label}</span> {value && <span> {value} </span>}{" "}
        </span>
        {/* <span>{value || label}</span> */}
        <ArrowDropDownIcon
          className={selectedButton ? "rotate" : "not-rotate"}
        />
        <div className={`drop-down-container ${selectedButton ? "show" : ""}`}>
          {listData?.map((e,index) => {
            return (
              <span
              key={index}
                className={`${value === e ? "selected-value" : ""}`}
                onClick={() => onchangeYear(e, label)}
              >
                {e}
              </span>
            );
          })}
          {(!listData || listData?.length == 0) && <span>None</span>}
        </div>
      </button>
    </>
  );
};

export default Dropdown;
