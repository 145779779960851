import React, { useEffect, useRef, useState } from "react";
import {
  Area,
  AreaChart,
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { saveAs } from "file-saver";
import { colors1, colors3 } from "../../../../colors/color";
import CustomizeTootip from "../../../../components/CustomizeTootip";
import CustomizeLegend from "../../../../components/CustomizeLegend";
import Printer2 from "../../../../components/Printer2";
import { exportComponentAsJPEG } from "react-component-export-image";
import { saveToSvg } from "../../../../constants/Utils";
import { CSVLink } from "react-csv";
import GraphLoader from "../../../../components/GraphLoader";

const PvsystActualGHI = ({ GHIGTI, loading }) => {
  const csvDownloadRef = useRef(null);
  const graphRef = useRef(null);
  const [showBarData, setShowBarData] = useState({});
  const [data, setData] = useState(null);

  useEffect(() => {
    if (GHIGTI && GHIGTI?.length > 0) {
      let result = [];
      let obj = {},
        obj2 = {};

      GHIGTI?.forEach((e) => {
        obj2 = {};
        obj2["name"] = e["name"];
        obj2["GHI (KWh/m^2)"] = e["GHI (KWh/m^2)"]?.toFixed(2);
        obj2["Actual GHI (kWh/m^2)"] = e["Actual GHI (kWh/m^2)"]?.toFixed(2);
        obj2[`Pvsyst GHI Vs Actual GHI`] =
          e[`Pvsyst GHI Vs Actual GHI`]?.toFixed(2);
        result.push(obj2);
      });
      Object.keys(GHIGTI[0]).forEach((e) => {
        if (
          e === `GHI (KWh/m^2)` ||
          e === "Actual GHI (kWh/m^2)" ||
          e === "Pvsyst GHI Vs Actual GHI"
        ) {
          obj[e] = true;
        }
      });
      setData(result);
      obj["all"] = true;
      setShowBarData(obj);
    }
  }, [GHIGTI]);
  return (
    <>
      {" "}
      <div
        style={{
          padding: "0.5rem 1.5rem",
          position: "sticky",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          zIndex: "1",
        }}
      >
        <h5>
          PVsyst GHI (kWh/m^2) vs Actual GHI (kWh/m^2) vs PVsyst Vs Actual GHI
          (kWh/m^2)
        </h5>
        <Printer2
          clickhandler={() => csvDownloadRef.current.link.click()}
          jpgDownload={() =>
          {
            if(!graphRef)return null
          
            setTimeout(async () => {
              document.getElementsByClassName("cpy_right")[1].style.display =
                "block";
              exportComponentAsJPEG(graphRef, {
                fileName: "graph",
              });
              document.getElementsByClassName("cpy_right")[1].style.display =
                "none";
            }, 100)
          }
          }
          svgDownload={async () => {
            if(!graphRef.current)return null
            document.getElementsByClassName("cpy_right")[1].style.display =
              "block";
            const svgData = await saveToSvg(graphRef.current.container);
            document.getElementsByClassName("cpy_right")[1].style.display =
              "none";
            saveAs(svgData, "graph.svg");
          }}
        />
      </div>
      <CSVLink
        data={
          GHIGTI?.reduce((acc, curr) => {
            acc.push({
              Date: curr.name,
              PVsystGHI: curr["GHI (KWh/m^2)"],
              ActualGHI: curr["Actual GHI (kWh/m^2)"],
              PVsystGHIvsActualGHI: curr["Pvsyst GHI Vs Actual GHI"],
            });
            return acc;
          }, []) || []
        }
        filename="data.csv"
        className="hidden"
        target="_blank"
        ref={csvDownloadRef}
      />
      <div
        style={{
          minWidth:
            GHIGTI?.length > 0
              ? GHIGTI.length * Object.keys(GHIGTI[0]).length * 0.8 - 5 + "vw"
              : "100%",
        }}
        className="detailed-responsive-container-2"
      >
        <ResponsiveContainer>
          {loading ? (
            <div
              style={{
                position: "absolute",
                top: "0",
                bottom: "0",
                right: "0",
                left: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <GraphLoader />
            </div>
          ) : (
            <ComposedChart
              data={data || []}
              margin={{
                top: 5,
                right: 5,
                bottom: 5,
                left: 5,
              }}
              barCategoryGap={"20%"}
              ref={graphRef}
            >
              <CartesianGrid stroke="grey" strokeWidth={0.3} />
              <XAxis
                dataKey="name"
                fontSize={"0.8rem"}
                fontWeight={600}
                tickLine={false}
                axisLine={false}
                tickMargin={5}
              />
              <YAxis
                fontSize={"0.8rem"}
                fontWeight={600}
                tickLine={false}
                tickMargin={5}
                tickFormatter={(v) =>
                  v >= 1000 ? parseFloat(v / 1000).toFixed(0) + "k" : v
                }
                yAxisId="left-axis"
                //  label={{ value: "Energy", angle: -90,x:20, position: "insideLeft"}}
                // label={<AxisLabel axisType='yAxis' x={-23} y={-5}>PVsyst GHI & Actual GHI</AxisLabel>}
                label={{
                  value: "PVsyst GHI & Actual GHI",
                  angle: -90,
                  position: "insideBottomLeft",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                }}
                domain={[
                  Math.floor(
                    data?.reduce(
                      (acc, curr, index) =>
                        Math.min(
                          curr["GHI (KWh/m^2)"],
                          curr["Actual GHI (kWh/m^2)"],
                          acc
                        ),
                      Infinity
                    )
                  ),
                  Math.ceil(
                    data?.reduce(
                      (acc, curr, index) =>
                        Math.max(
                          curr["GHI (KWh/m^2)"],
                          curr["Actual GHI (kWh/m^2)"],
                          acc
                        ),
                      -Infinity
                    )
                  ),
                ]}
              />
              <YAxis
                fontSize={"0.8rem"}
                fontWeight={600}
                tickLine={false}
                tickMargin={5}
                yAxisId="right-axis"
                orientation="right"
                // label={<AxisLabel axisType='yAxis' x={28} y={300}>PVsyst Vs Actual GHI</AxisLabel>}
                label={{
                  value: "PVsyst Vs Actual GHI",
                  angle: 90,
                  position: "insideBottomRight",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  // dy: -140,
                  // dx: 10,
                }}
                domain={[
                  Math.floor(
                    data?.reduce(
                      (acc, curr, index) =>
                        Math.min(curr["Pvsyst GHI Vs Actual GHI"], acc),
                      Infinity
                    )
                  ) - 2,
                  Math.ceil(
                    data?.reduce(
                      (acc, curr, index) =>
                        Math.max(curr["Pvsyst GHI Vs Actual GHI"], acc),
                      -Infinity
                    )
                  ) + 2,
                ]}
              />
              <Tooltip
                content={
                  <CustomizeTootip
                    active={false}
                    payload={[]}
                    label={""}
                    TooltipValues={[
                      "PVsyst GHI",
                      "Actual GHI",
                      "PVsyst GHI vs Actual GHI",
                    ]}
                  />
                }
              />
              <Legend
                content={
                  <CustomizeLegend
                    active={false}
                    payload={[]}
                    LegendValues={[
                      "PVsyst GHI",
                      "Actual GHI",
                      "PVsyst GHI vs Actual GHI",
                    ]}
                    data={showBarData}
                    setData={setShowBarData}
                    styles={{ fontSize: "0.8rem" }}
                  />
                }
              />

              
              <Bar
              hide={showBarData["GHI (KWh/m^2)"]? false : true}
              dataKey="GHI (KWh/m^2)"
              fill={`${colors3[0]}`}
              yAxisId="left-axis"
            />
            <Bar
              hide={showBarData['Actual GHI (kWh/m^2)']? false : true}
              dataKey="Actual GHI (kWh/m^2)"
              fill={`${colors3[1]}`}
              yAxisId="left-axis"
            />
            <Line
              hide={showBarData["Pvsyst GHI Vs Actual GHI"] ? false : true}
              type="monotone"
              dataKey="Pvsyst GHI Vs Actual GHI"
              strokeWidth={3}
              stroke={`${colors1[4]}`}
              yAxisId="right-axis"
            />
            </ComposedChart>
          )}
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default PvsystActualGHI;
