// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ##### -> Components ->  PresCripModel ##### */
.prescriptive-responsive-container{
    height: 75vh;
    min-width: 500px;
}
@media screen and (max-width:900px) {
    
    .prescriptive-responsive-container{
        height: 50vh;
    }
}`, "",{"version":3,"sources":["webpack://./src/modules/countries/components/prescriptiveModel/prescritive.css"],"names":[],"mappings":"AAAA,gDAAgD;AAChD;IACI,YAAY;IACZ,gBAAgB;AACpB;AACA;;IAEI;QACI,YAAY;IAChB;AACJ","sourcesContent":["/* ##### -> Components ->  PresCripModel ##### */\n.prescriptive-responsive-container{\n    height: 75vh;\n    min-width: 500px;\n}\n@media screen and (max-width:900px) {\n    \n    .prescriptive-responsive-container{\n        height: 50vh;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
