import React, { useRef, useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { colors3 } from "../../../../colors/color";
import CustomizeTootip from "../../../../components/CustomizeTootip";
import CustomizeLegend from "../../../../components/CustomizeLegend";
import { CSVLink } from "react-csv";
import { saveAs } from "file-saver";
import "./prescritive.css";
import { exportComponentAsJPEG } from "react-component-export-image";
import { saveToSvg } from "../../../../constants/Utils";
import Printer2 from "../../../../components/Printer2";

const data = [
  {
    name: "Module",
    Loss: 5.25,
    // fill: "#FF0000"
    fill: "#de425b",
  },
  {
    name: "Soiling",
    Loss: 3.5,
    // fill:"rgb(227, 94, 27)"
    fill: "#e18745",
  },
  {
    name: "Shadow",
    Loss: 1.3,
    // fill: "#FFA500"
    fill: "#dac767",
  },
  {
    name: "Resistance",
    Loss: 1,
    // fill:"rgb(223, 200, 51)"
    fill: "#78ab63",
  },
  {
    name: "Overheating",
    Loss: 1,
    // fill: "rgb(27, 84, 227)"
    // fill: "rgb(24, 134, 245)"
    // fill:"rgb(252, 237, 107)"
    // fill:"rgb(94, 216, 253)"
    fill: "#488f31",
  },
];

const PrescriptiveLoss = () => {
    const [dataSet, setDataSet] = useState(data);
    const [showCheckBox, setShowCheckBox] = useState({
      Module: true,
      Soiling: true,
      Shadow: true,
      Resistance: true,
      Overheating: true,
    });
  
    const [showLoss, setShowLoss] = useState({
      loss: true,
      all: true,
    });
    /* ###   Reference to download graph data in CSV format   ### */
  
    const lossDownloadRef = useRef(null);
  
    /* ###   Reference for Graph container TO Download In Jpg and Svg format   ### */
  
    const downloadRef1 = useRef(null);
  
    /* ###   Function To Visible Selected Graph Values   ### */
  
   
  return (
    <> <CSVLink
    data={
      dataSet?.reduce((acc, curr) => {
        acc.push({
          name: curr.name,
          LossPercentage: curr.Loss,
        });
        return acc;
      }, []) || []
    }
    filename="data.csv"
    className="hidden"
    ref={lossDownloadRef}
    target="_blank"
  />
  <div
    style={{
      padding: "0.5rem 1.5rem",
      position: "sticky",
      left: "0",
      right: "0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      zIndex: "1",
    }}
  >
    <h3>Loss Flow (%)</h3>
    <Printer2
      clickhandler={() => lossDownloadRef.current.link.click()}
      jpgDownload={() => {
        document.getElementsByClassName(
          "cpy_right"
        )[0].style.display = "block";
        exportComponentAsJPEG(downloadRef1, { fileName: "graph" });
        document.getElementsByClassName(
          "cpy_right"
        )[0].style.display = "none";
      }}
      svgDownload={async () => {
        document.getElementsByClassName(
          "cpy_right"
        )[0].style.display = "block";
        const svgData = await saveToSvg(
          downloadRef1.current.container
        );
        document.getElementsByClassName(
          "cpy_right"
        )[0].style.display = "none";
        saveAs(svgData, "graph.svg");
      }}
    />
  </div>

  <div className="prescriptive-responsive-container" style={{ width: "100%" }}>
    <ResponsiveContainer>
      <ComposedChart
        data={dataSet || []}
        ref={downloadRef1}
        margin={{
          right: 5,
          top: 5,
          bottom: 5,
          left: 5,
        }}
        barCategoryGap={"25%"}
      >
        <XAxis
          fontSize={"0.8rem"}
          interval={0}
          dataKey="name"
          tickLine={false}
          axisLine={false}
          tickMargin={5}
        />
        <CartesianGrid stroke="grey" strokeWidth={0.2} />
        <YAxis
          type="number"
          fontSize={"0.8rem"}
          dataKey={() =>
            Math.ceil(
              dataSet?.reduce(
                (acc, curr, index) =>
                  curr.Loss > acc ? curr.Loss : acc,
                -Infinity
              )
            ) + 1
          }
          domain={[
            Math.floor(
              dataSet?.reduce(
                (acc, curr, index) =>
                  curr.Loss < acc ? curr.Loss : acc,
                Infinity
              )
            ) - 1,
            "dataMax",
          ]}
          tickLine={false}
          tickMargin={5}
        />
        <Legend
          content={
            <CustomizeLegend
              active={false}
              payload={[]}
              label={""}
              LegendValues={["Loss"]}
              data={showLoss}
              setData={setShowLoss}
              styles={{fontSize:"1rem"}}
            />
          }
        />
        <Tooltip
          content={
            <CustomizeTootip
              active={false}
              payload={[]}
              label={""}
              TooltipValues={["Loss"]}
            />
          }
          contentStyle={{ fontSize: "0.7rem" }}
        />
        {
          <Bar
            hide={showLoss.loss ? false : true}
            dataKey="Loss"
            fill={colors3[0]}
            onTransitionEnd={"1s all"}
          >
            <LabelList
              dataKey="Loss"
              position="top"
              fontSize={"1.8vmax"}
              fontWeight={600}
              fontFamily="Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;"
            />
          </Bar>
        }
      </ComposedChart>
    </ResponsiveContainer>
  </div></>
  )
}

export default PrescriptiveLoss