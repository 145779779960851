import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";

import SmbLossYearly from "./SmbLossYearly";
import SmbLossMonthly from "./SmbLossMonthly";
import { useSelector } from "react-redux";

const SCBSMB = ({
  selectedValue,
  setSelectedValue,
  selectedLebel,
  scbsmbDetail,
  setscbsmbDetail,
  sestSelectedLebel,
  databaseStr
}) => {
  const { projectDetail } = useSelector((state) => state.user);
  useEffect(() => {

  }, [projectDetail, selectedValue]);

  return (
    <Grid
      container
      xl={12}
      style={{
      }}
    >
      <Grid
        item
        xl={11.8}
        lg={11.8}
        md={11.8}
        sm={11.8}
        xs={11.8}
       
        // borderRadius={"14px"}
        style={{
          background:
            "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
          margin: "0vmax auto 1vmax",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "1vmax",
        }}
      >
        {/*  ###   SMB (String) Average Yearly Loss Graph Container   ### */}

        <Grid
        item
      
        xs={12}
          style={{
            background: "white",
            padding: "0.5vmax 1vmax",
            position: "relative",
            overflow: "auto"
          }}
        >
          <SmbLossYearly
          databaseStr={databaseStr}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            selectedLebel={selectedLebel}
            scbsmbDetail={scbsmbDetail}
            sestSelectedLebel={sestSelectedLebel}
          />
        </Grid>
      </Grid>
      {/*  ###   SMB (String) Average Monthly Loss Graph Container   ### */}

      <Grid
        item
        xl={11.8}
        lg={11.8}
        md={11.8}
        sm={11.8}
        xs={11.8}
        // borderRadius={"14px"}
        style={{
          background:
            "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
          margin: "0vmax auto 1vmax",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "1vmax",
        }}
      >
        <Grid
        item
          lg={12}
          style={{
            background: "white",
            // borderRadius: "14px",
            padding: "0.5vmax 1vmax",
            position: "relative",
            width: "100%",
            overflow: "auto",
          }}
        >
          <SmbLossMonthly />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SCBSMB;
