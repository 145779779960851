import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";


/* ###  String/SMB Details Component  ### */ 

const SmbDetails = () => {
    const {  projectDetail } = useSelector((state) => state.user);


    return (
        
        <div style={{ width: "100%"}}>
            <h2 style={{ textAlign: "center", padding: "2vmin 0" }}>SMB And String Details</h2>

            <TableContainer sx={{ padding: "0vmax 2vmin", width: "100%" ,whiteSpace:"nowrap"}}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ backgroundColor: "#edeaea" }}>
                            <TableCell style={{ fontWeight: "bolder", fontSize: "2vmin" }}>
                                Array No
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: "bolder", fontSize: "2vmin" }}>
                                Inverter No
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: "bolder", fontSize: "2vmin" }}>
                                SMB No
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: "bolder", fontSize: "2vmin" }}>
                                Module (Wp)
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: "bolder", fontSize: "2vmin" }}>
                                Total String
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: "bolder", fontSize: "2vmin" }}>
                                Module/String
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: "bolder", fontSize: "2vmin" }}>
                                Impp of Each String
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: "bolder", fontSize: "2vmin" }}>
                                Each {projectDetail?.plantDetail?.plantDetail?.string} Current (A)
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: "bolder", fontSize: "2vmin" }}>
                                Voltage (V)
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {/* blockNo(pin):"Sub-Array-1"
                        inverterNo */}

                        {projectDetail?.plantDetail?.scbsmbStringArray?.map((value, index, arr) => (
                            <TableRow key={index}>
                                {(index === 0 && <TableCell align="left" style={{ fontSize: "2vmin" }}>{value.blockNo}</TableCell>) ||
                                    (index < projectDetail?.plantDetail?.scbsmbStringArray?.length && arr[index].blockNo !== arr[index - 1].blockNo && <TableCell align="left" style={{ fontSize: "2vmin" }}>{arr[index].blockNo}</TableCell>) ||
                                    <TableCell align="left" style={{ fontSize: "2vmin" }}>
                                        <p></p>
                                    </TableCell>
                                }
                                {(index === 0 && <TableCell align="center" style={{ fontSize: "2vmin" }}>{value.inverterNo} ({value?.inverterType})</TableCell>) ||
                                    (index < projectDetail?.plantDetail?.scbsmbStringArray?.length && arr[index].inverterNo !== arr[index - 1].inverterNo && <TableCell align="center" style={{ fontSize: "2vmin" }}>{arr[index].inverterNo} ({value?.inverterType})</TableCell>) ||
                                    <TableCell align="center" style={{ fontSize: "2vmin" }}>
                                        <p></p>
                                    </TableCell>
                                }
                                <TableCell align="center" style={{ fontSize: "2vmin" }}>{value.smbNo}</TableCell>
                                <TableCell align="center" style={{ fontSize: "2vmin" }}>{value.moduleWP}</TableCell>
                                <TableCell align="center" style={{ fontSize: "2vmin" }}>{value.totalString}</TableCell>
                                <TableCell align="center" style={{ fontSize: "2vmin" }}>{value.modulePerString}</TableCell>
                                <TableCell align="center" style={{ fontSize: "2vmin" }}>{value.imppEachString}</TableCell>
                                <TableCell align="center" style={{ fontSize: "2vmin" }}>{value.smbCurrentEach}</TableCell>
                                <TableCell align="center" style={{ fontSize: "2vmin" }}>{value.voltage}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default SmbDetails;