// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* #####  Default Styles From App Component  ##### */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.App {
  width: 100%;
  height: 100vh;
  overflow: auto !important;
}

ul {
  padding: none !important;
}

.MuiList-root,
.MuiList-padding,
.MuiMenu-list,
.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0 !important;
}

a {
  text-decoration: none;
  color: black;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}

.apexcharts-toolbar {
  z-index: 0 !important;
  right: 3.5vmax !important;
  top: 1rem !important;
}

.country-module-container{
   min-height: calc(100vh - 60px) ;
   position: relative;
   transition: .5s ease;
    display: flex;
  /* align-items: center;*/
   justify-content: center;
   flex-direction: column; 
}



.country-module-container .selected-detail-container>span{
  padding: 0 0.3rem;
  font-weight: 800;
  color: rgb(3, 99, 125);
  font-size: 1.1rem;
  /* border-right: 3px solid wheat; */
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,oDAAoD;;AAEpD;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,sEAAsE;AACxE;;AAEA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;;;;EAIE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd;;AAEA;;EAEE,wBAAwB;EACxB,0BAA0B;EAC1B,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,oBAAoB;AACtB;;AAEA;GACG,+BAA+B;GAC/B,kBAAkB;GAClB,oBAAoB;IACnB,aAAa;EACf,wBAAwB;GACvB,uBAAuB;GACvB,sBAAsB;AACzB;;;;AAIA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,sBAAsB;EACtB,iBAAiB;EACjB,mCAAmC;AACrC","sourcesContent":["/* #####  Default Styles From App Component  ##### */\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: Cambria, Cochin, Georgia, Times, \"Times New Roman\", serif;\n}\n\n.App {\n  width: 100%;\n  height: 100vh;\n  overflow: auto !important;\n}\n\nul {\n  padding: none !important;\n}\n\n.MuiList-root,\n.MuiList-padding,\n.MuiMenu-list,\n.css-6hp17o-MuiList-root-MuiMenu-list {\n  padding: 0 !important;\n}\n\na {\n  text-decoration: none;\n  color: black;\n}\n\ninput[type=\"number\"]::-webkit-inner-spin-button,\ninput[type=\"number\"]::-webkit-outer-spin-button {\n  -webkit-appearance: none;\n  -moz-appearance: textfield;\n  margin: 0;\n}\n\n.apexcharts-toolbar {\n  z-index: 0 !important;\n  right: 3.5vmax !important;\n  top: 1rem !important;\n}\n\n.country-module-container{\n   min-height: calc(100vh - 60px) ;\n   position: relative;\n   transition: .5s ease;\n    display: flex;\n  /* align-items: center;*/\n   justify-content: center;\n   flex-direction: column; \n}\n\n\n\n.country-module-container .selected-detail-container>span{\n  padding: 0 0.3rem;\n  font-weight: 800;\n  color: rgb(3, 99, 125);\n  font-size: 1.1rem;\n  /* border-right: 3px solid wheat; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
