import React, { useState } from "react";
import { ListSubheader, MenuItem, Box } from "@mui/material"; // Assuming you are using MUI
let data = {
  _id: "66e6b1bd4db915cbf85c5169",
  clientEmailID: "ipcl@gmail.com",
  projectName: "IndiaIPCLPLSPL",
  stringDetail: {
    stringType: "SCB",
    stringArray: [
      {
        name: "Block1",
        inverters: [
          { name: "Inverter1", listSCBs: ["SCB1", "SCB2", "SCB3"] },
          { name: "inverter2", listSCBs: ["SCB4", "SCB5", "SCB6"] },
          { name: "Inverter3", listSCBs: ["SCB1", "SCB2", "SCB3"] },
          { name: "inverter4", listSCBs: ["SCB4", "SCB5", "SCB6"] },
        ],
      },
      {
        name: "Block2",
        inverters: [
          { name: "inverter1", listSCBs: ["SCB7", "SCB8"] },
          { name: "inverter2", listSCBs: ["SCB9", "SCB10"] },
        ],
      },
    ],
  },
};
const YourComponent = () => {
  // State to track which subheader is expanded
  const [expanded, setExpanded] = useState({});

  // Handle the click on the ListSubheader to toggle visibility
  const handleToggle = (key) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [key]: !prevExpanded[key], // Toggle the state for the specific key
    }));
  };

  return (
    <>
      {data.stringDetail.stringArray.map((e) => {
        return (
          <Box key={e.name}>
            {" "}
            {/* Use Box or div for layout */}
            {/* First Subheader */}
            <ListSubheader className="SubHeader">
              <span onClick={() => handleToggle(e.name)}>
                {e.name}
                <i
                  className={`fa-solid fa-angle-down ${
                    expanded[e.name] ? "active" : ""
                  }`}
                ></i>
              </span>
            </ListSubheader>
            {/* Conditionally show inverters if expanded */}
            {expanded[e.name] &&
              e?.inverters?.map((ee) => {
                return (
                  <Box key={ee.name}>
                    {" "}
                    {/* Ensure a valid key */}
                    <ListSubheader className="SubHeader">
                      <span onClick={() => handleToggle(e.name + ee.name)}>
                        {ee.name}
                        <i
                          className={`fa-solid fa-angle-down ${
                            expanded[e.name + ee.name] ? "active" : ""
                          }`}
                        ></i>
                      </span>
                    </ListSubheader>
                    {/* Conditionally show SCBs if expanded */}
                    {expanded[e.name + ee.name] &&
                      ee?.listSCBs?.map((eee, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={e.url}
                            onClick={() => {
                               // Perform your action
                              setExpanded({}); // Close all dropdowns
                            }}
                          >
                            {eee}
                          </MenuItem>
                        );
                      })}
                  </Box>
                );
              })}
          </Box>
        );
      })}
    </>
  );
};

export default YourComponent;
