import React from 'react'
import { Link } from 'react-router-dom'


/* ##### Footer Component ##### */ 

const Footer = () => {
    return (
        <div style={{ height:"6vh",zIndex:"1", backgroundColor:"#122b4f",display:"flex",alignItems:"center",justifyContent:"center",color:"white",fontWeight:"600"}}>
            <span style={{fontSize:"1.8vmin"}}> &#169;&nbsp;  </span> <span style={{fontSize:"1.8vmin"}} > <Link target='_blank' to="https://greenenco.co.uk" style={{color:"white"}} >GreenEnco.</Link>  All Rights Reserved.</span> 
        </div>
    )
}

export default Footer