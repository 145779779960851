import React, { useEffect, useState } from "react";
import "./Login.css";
import Buttons from "../../../components/ButtonsComp";
import { useNavigate } from "react-router-dom";
import PageURL from "../../../constants/PageURL";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { getUser, userLogin } from "../../../actions/userActions";
import { clear_errors, get_user_reset, user_login_reset } from "../../../constants/dataConstants";
import Loader from "../../../components/Loader";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";


/* ##### Login Component To Render Login Container  ##### */

const LoginComponent = (props) => {
  const navigate = useNavigate();
  const [form, setForm] = useState({ userName: "", password: "" });
  const dispatch = useDispatch();
  const [openEye, setopenEye] = useState(false)
  const [text, setText] = useState(true)
  const { user, error, loading,loading3, isLogin, auth_token,existUser } = useSelector(state => state.user)
  const cookie = new Cookies()

/* #####  User Login Handler  ##### */

  const handleLogin = async () => {
    if (form.userName.trim() === "" || form.password.trim() === "") {
      toast.error("Enter a valid userName and password")
      return;
    }
    else if (!form.userName.trim().match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
      toast.error("Enter a valid username")
      return;
    } else {
      dispatch(userLogin(form))
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: clear_errors })
    }
    if (isLogin  ) {
      dispatch(getUser())
      dispatch({ type: user_login_reset })
    }
    if(existUser){
      toast.success("Logged In Successfully!")
      dispatch({type:get_user_reset})
      navigate(PageURL.COUNTRYDEFAULT);
    }

  }, [error, navigate, dispatch, ,existUser,isLogin])

  return (
    <div className="login-box" style={{width:"100%"}}>
      {
        (loading|| loading3)  && <Loader />
      }
      <h2 style={{ textAlign: "center" }}>Client Login</h2>
      <div className="inputs">
        <input
          type="text"
          placeholder="Username"
          style={{
            textAlign: "center",
            fontWeight: "bolder",
            color: "black",
          }}
          value={form?.userName}
          onChange={(e) => {
            setForm({ ...form, userName: e.target.value });
          }}
        />
      </div>
      <div className="inputs" >
        <input
          type={text ? "password" : "text"}
          placeholder="Password"
          style={{
            textAlign: "center",
            fontWeight: "bolder",
          }}
          value={form?.password}
          onChange={(e) => {
            e.target.value.length>0?setopenEye(true):setopenEye(false);
            setForm({ ...form, password: e.target.value });
          }}
        />
        <div style={{ position: "relative" }}>
        <i onClick={() => setText(!text)} className={`${!openEye?"":openEye && text?"fa-solid fa-eye-slash":"fa-solid fa-eye"}`}
        style={{
          position: "absolute", right: "1.5vmin", top:"-0.8vmin",
          color: "black", fontSize: "2vmin"
        }}
        ></i>
        </div>
      </div>
      <div className="buttons" >
        <Buttons
          type="contained"
          buttonType="primary"
          buttonText="Login"
          onClick={() => {
            handleLogin();
          }}
        ></Buttons>
        <Buttons
          type="outlined"
          buttonType="secondary"
          buttonText="Reset"
          onClick={() => {
            setForm({ userName: "", password: "" });
          }}
        ></Buttons>

      </div>
      <div style={{position:"relative",bottom:"0.5vmax"}}>
        <span className="website"style={{ color: "orange", fontWeight: "900", fontSize: "1vmax" }}>Our Other Services: <Link
         style={{ color: "white", fontWeight: "600", fontSize: "1vmax" }}
            target="_blank" to="https://greenenco.co.uk" className="forgot_password">
            Please visit our website</Link>
        </span>
      </div>
    </div>
  );
};

export default LoginComponent;
